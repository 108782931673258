import React from "react";
import { VStack, Text, Select } from "@chakra-ui/react";
import { NodeData, Tool } from "../type";

interface ToolSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
  availableTools: Tool[];
}

const ToolSection: React.FC<ToolSectionProps> = ({
  nodeData,
  setNodeData,
  availableTools,
}) => {
  return (
    <VStack alignItems="start">
      <Text fontSize="lg" fontWeight="bold" as="p">
        Select Tool:
      </Text>
      <Select
        placeholder="Select Tool"
        value={nodeData.safeTools!.length > 0 ? nodeData.safeTools![0] : ""}
        onChange={(e) => {
          setNodeData((prevState) => ({
            ...prevState,
            safeTools: [e.target.value],
          }));
        }}
        overflow="hidden"
      >
        {availableTools.map((tool) => (
          <option key={tool.id} value={tool.name}>
            {tool.name}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default ToolSection;

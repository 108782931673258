import React from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  VStack,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { Link, Outlet, useLocation } from "react-router-dom";
import SideNav from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import { SidebarProvider, useSidebar } from "../../contexts/SidebarContext";
import { FiUsers, FiMessageCircle, FiPhoneCall, FiBook } from "react-icons/fi";

interface DashboardCardProps {
  title: string;
  description: string;
  link: string;
  icon: React.ElementType;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  description,
  link,
  icon,
}) => (
  <Box
    as={Link}
    to={link}
    p={4}
    borderWidth="1px"
    borderRadius="md"
    boxShadow="sm"
    transition="all 0.2s"
    _hover={{
      boxShadow: "md",
      borderColor: "blue.500",
      bg: "blue.50",
    }}
    bg="white"
    borderColor="gray.200"
  >
    <VStack align="start" spacing={2}>
      <Icon as={icon} boxSize={6} color="gray.500" />
      <Heading size="sm" fontWeight="semibold">
        {title}
      </Heading>
      <Text fontSize="sm" color="gray.600">
        {description}
      </Text>
    </VStack>
  </Box>
);

const DashboardContent: React.FC = () => {
  const { isExpanded } = useSidebar();
  const location = useLocation();

  const isRootDashboard = location.pathname === "/dashboard";

  const dashboardItems = [
    {
      title: "Conversational Agents",
      description: "Design infinitely complex, branching conversational flows",
      link: "/dashboard/agents",
      icon: FiMessageCircle,
    },
    {
      title: "Voices",
      description: "Create or modify your agent voices",
      link: "/dashboard/voices",
      icon: FiPhoneCall,
    },
    {
      title: "Campaigns",
      description: "Manage your campaigns",
      link: "/dashboard/campaigns",
      icon: FiUsers,
    },
    {
      title: "Knowledge Base",
      description: "Manage and organize your knowledge resources",
      link: "/dashboard/knowledge-base",
      icon: FiBook,
    },
  ];

  return (
    <Flex height="100vh" flexDirection="column">
      <TopNav />
      <Flex flex={1} overflow="hidden">
        <Box
          width={isExpanded ? "240px" : "60px"}
          flexShrink={0}
          transition="width 0.3s"
        >
          <SideNav />
        </Box>
        <Box flex={1} overflowY="auto" p={6} transition="margin-left 0.3s">
          {isRootDashboard ? (
            <VStack spacing={6} align="stretch">
              <VStack spacing={1} align="stretch">
                <Heading size="lg">Welcome!</Heading>
                <Text fontSize="md" color="gray.600">
                  What would you like to do today?
                </Text>
              </VStack>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {dashboardItems.map((item, index) => (
                  <DashboardCard key={index} {...item} />
                ))}
              </SimpleGrid>
            </VStack>
          ) : (
            <Outlet />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const Dashboard: React.FC = () => {
  return (
    <SidebarProvider>
      <DashboardContent />
    </SidebarProvider>
  );
};

export default Dashboard;

import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

// Define props type
interface ModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: string; // Added size prop
  footer?: boolean; // Added footer prop
  header: string;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpen,
  onClose,
  children,
  size = "xl", // Default value for size
  footer = true, // Default value for footer
  header = "",
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {footer && ( // Conditionally render footer
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose} size={"sm"}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose} size={"sm"}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalWrapper;

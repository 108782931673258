import React, { useState, useCallback } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  ButtonGroup,
  Tooltip,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
  getPresignedUrls,
  createKnowledgeBase,
  uploadFileToS3,
} from "../../api/knowledgebase";

type KnowledgeBaseType = "graph" | "vector";

const CreateKnowledgeBase: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [knowledgeBaseType, setKnowledgeBaseType] =
    useState<KnowledgeBaseType>("vector");

  //   todo, update useauth hook to get org id
  const organizationId = "1";

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
    },
  });

  const removeFile = (indexToRemove: number) => {
    setFiles((prev) => prev.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!title.trim()) {
        toast({
          title: "Error",
          description: "Please enter a title",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (files.length === 0) {
        toast({
          title: "Error",
          description: "Please upload at least one file",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const presignedUrls = await getPresignedUrls(
        organizationId,
        files.map((f) => ({ name: f.name, type: f.type }))
      );
      console.log(presignedUrls);

      const uploadPromises = files.map(async (file, index) => {
        const { uploadUrl } = presignedUrls[index];
        await uploadFileToS3(uploadUrl, file);
      });

      const presignedUrlObjects = await Promise.all(uploadPromises);
      console.log(presignedUrlObjects);
      const s3Uris = presignedUrls.map((obj) => obj.s3Uri);
      console.log(s3Uris);

      await createKnowledgeBase({
        organizationId,
        title,
        description,
        knowledgeBaseType,
        s3Uris,
      });

      toast({
        title: "Success",
        description: "Knowledge base created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/dashboard/knowledge-base");
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to create knowledge base",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box height="100%" p={4}>
      <Text fontSize="2xl" mb={6}>
        Create Knowledge Base
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={6} align="stretch">
          <FormControl isRequired>
            <FormLabel>Knowledge Base Type</FormLabel>
            <ButtonGroup isAttached width="100%">
              <Button
                flex={1}
                onClick={() => setKnowledgeBaseType("vector")}
                colorScheme={knowledgeBaseType === "vector" ? "blue" : "gray"}
                variant={knowledgeBaseType === "vector" ? "solid" : "outline"}
                type="button"
              >
                Direct
              </Button>
              <Tooltip
                label="Relational knowledge bases are a premium feature with additional costs"
                placement="top"
                hasArrow
              >
                <Button
                  flex={1}
                  onClick={() => setKnowledgeBaseType("graph")}
                  colorScheme={knowledgeBaseType === "graph" ? "blue" : "gray"}
                  variant={knowledgeBaseType === "graph" ? "solid" : "outline"}
                  type="button"
                >
                  Relational
                </Button>
              </Tooltip>
            </ButtonGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter knowledge base title"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter knowledge base description"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Upload Files</FormLabel>
            <Box
              {...getRootProps()}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              borderColor={isDragActive ? "blue.500" : "gray.200"}
              p={6}
              textAlign="center"
              bg={isDragActive ? "gray.50" : "white"}
              cursor="pointer"
              transition="all 0.2s"
              _hover={{
                borderColor: "blue.500",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text>Drop the files here...</Text>
              ) : (
                <Text>Drag and drop files here, or click to select files</Text>
              )}
              <Text fontSize="sm" color="gray.500" mt={2}>
                Supported formats: PDF, DOC, DOCX, TXT, CSV
              </Text>
            </Box>
          </FormControl>

          {files.length > 0 && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                Selected Files:
              </Text>
              <VStack align="stretch" spacing={2}>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <Text fontSize="sm">
                      {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                    </Text>
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => removeFile(index)}
                    >
                      ✕
                    </Button>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={isLoading}
            loadingText="Creating..."
          >
            Create Knowledge Base
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateKnowledgeBase;

import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  RadioGroup,
  Radio,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  IconButton,
  FormErrorMessage,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, InfoIcon } from "@chakra-ui/icons";

interface SchedulingDetailsProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

const SchedulingDetails: React.FC<SchedulingDetailsProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  const handleStartTypeChange = (value: string) => {
    setFormData({
      ...formData,
      startType: value,
    });
  };

  const handleCallWindowAdd = () => {
    setFormData({
      ...formData,
      callWindows: [...formData.callWindows, { start: "", end: "" }],
    });
  };

  const handleCallWindowRemove = (index: number) => {
    const newCallWindows = formData.callWindows.filter(
      (_: any, i: number) => i !== index
    );
    setFormData({
      ...formData,
      callWindows: newCallWindows,
    });
  };

  return (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.startType}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Start Time</FormLabel>
          <Tooltip
            label="Choose when to start the campaign - immediately or at a scheduled time"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <RadioGroup value={formData.startType} onChange={handleStartTypeChange}>
          <HStack spacing={4}>
            <Radio value="immediate">Immediate</Radio>
            <Radio value="scheduled">Scheduled</Radio>
          </HStack>
        </RadioGroup>
        <FormErrorMessage>{errors.startType}</FormErrorMessage>
      </FormControl>

      {formData.startType === "scheduled" && (
        <FormControl isRequired isInvalid={!!errors.startDateTime}>
          <HStack spacing={1} mb={1}>
            <FormLabel mb={0}>Start Date & Time</FormLabel>
            <Tooltip
              label="Set the exact date and time when the campaign should start"
              placement="top-start"
            >
              <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
            </Tooltip>
          </HStack>
          <Input
            type="datetime-local"
            name="startDateTime"
            value={formData.startDateTime}
            onChange={(e) =>
              setFormData({ ...formData, startDateTime: e.target.value })
            }
          />
          <FormErrorMessage>{errors.startDateTime}</FormErrorMessage>
        </FormControl>
      )}

      <FormControl isRequired isInvalid={!!errors.endDateTime}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>End Date & Time</FormLabel>
          <Tooltip
            label="Set when the campaign should stop - no calls will be made after this time"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Input
          type="datetime-local"
          name="endDateTime"
          value={formData.endDateTime}
          onChange={(e) =>
            setFormData({ ...formData, endDateTime: e.target.value })
          }
        />
        <FormErrorMessage>{errors.endDateTime}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.maxAttempts}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Maximum Attempts</FormLabel>
          <Tooltip
            label="Maximum number of times to attempt reaching each customer"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <NumberInput
          min={1}
          value={formData.maxAttempts}
          onChange={(value) =>
            setFormData({ ...formData, maxAttempts: parseInt(value) })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{errors.maxAttempts}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.callWindows}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Call Windows</FormLabel>
          <Tooltip
            label="Set time windows during which calls can be made"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <VStack spacing={2} align="stretch">
          {formData.callWindows.map((window: any, index: number) => (
            <HStack key={index}>
              <Input
                type="time"
                value={window.start}
                onChange={(e) => {
                  const newWindows = [...formData.callWindows];
                  newWindows[index].start = e.target.value;
                  setFormData({ ...formData, callWindows: newWindows });
                }}
              />
              <Input
                type="time"
                value={window.end}
                onChange={(e) => {
                  const newWindows = [...formData.callWindows];
                  newWindows[index].end = e.target.value;
                  setFormData({ ...formData, callWindows: newWindows });
                }}
              />
              {index > 0 && (
                <IconButton
                  aria-label="Remove call window"
                  icon={<DeleteIcon />}
                  onClick={() => handleCallWindowRemove(index)}
                />
              )}
            </HStack>
          ))}
          <Button
            leftIcon={<AddIcon />}
            onClick={handleCallWindowAdd}
            size="sm"
            alignSelf="start"
          >
            Add Call Window
          </Button>
        </VStack>
        <FormErrorMessage>{errors.callWindows}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.schedulingType}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Scheduling Type</FormLabel>
          <Tooltip
            label="Choose how to schedule call attempts - at fixed intervals or at specific times"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <RadioGroup
          value={formData.schedulingType}
          onChange={(value) =>
            setFormData({ ...formData, schedulingType: value })
          }
        >
          <HStack spacing={4}>
            <Radio value="interval">Interval</Radio>
            <Radio value="datetime">Date & Time</Radio>
          </HStack>
        </RadioGroup>
        <FormErrorMessage>{errors.schedulingType}</FormErrorMessage>
      </FormControl>

      {formData.schedulingType === "interval" ? (
        <FormControl isRequired isInvalid={!!errors.interval}>
          <HStack spacing={1} mb={1}>
            <FormLabel mb={0}>Interval (minutes)</FormLabel>
            <Tooltip
              label="Time to wait between call attempts"
              placement="top-start"
            >
              <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
            </Tooltip>
          </HStack>
          <NumberInput
            min={1}
            value={formData.interval}
            onChange={(value) =>
              setFormData({ ...formData, interval: parseInt(value) })
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>{errors.interval}</FormErrorMessage>
        </FormControl>
      ) : (
        <FormControl isRequired isInvalid={!!errors.scheduledDateTimes}>
          <HStack spacing={1} mb={1}>
            <FormLabel mb={0}>Scheduled Times</FormLabel>
            <Tooltip
              label="Set specific dates and times for each call attempt"
              placement="top-start"
            >
              <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
            </Tooltip>
          </HStack>
          <VStack align="stretch" spacing={2}>
            {Array.from({ length: formData.maxAttempts }).map((_, index) => (
              <Input
                key={index}
                type="datetime-local"
                value={formData.scheduledDateTimes[index] || ""}
                onChange={(e) => {
                  const newDateTimes = [...formData.scheduledDateTimes];
                  newDateTimes[index] = e.target.value;
                  setFormData({
                    ...formData,
                    scheduledDateTimes: newDateTimes,
                  });
                }}
              />
            ))}
          </VStack>
          <FormErrorMessage>{errors.scheduledDateTimes}</FormErrorMessage>
        </FormControl>
      )}
    </VStack>
  );
};

export default SchedulingDetails;

import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  FormErrorMessage,
  HStack,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

interface AboutCampaignProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

const AboutCampaign: React.FC<AboutCampaignProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.name}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Campaign Name</FormLabel>
          <Tooltip
            label="Enter a unique name to identify your campaign"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Input
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter campaign name"
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.description}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Campaign Description</FormLabel>
          <Tooltip
            label="Provide a detailed description of your campaign's purpose and goals"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Enter campaign description"
          rows={4}
        />
        <FormErrorMessage>{errors.description}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default AboutCampaign;

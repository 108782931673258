import { apiWithAuth } from "../apiService";

export const uploadCustomerFile = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiWithAuth.post("/customers/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to upload customer file");
  }
  return response.data.data;
};

export const createCustomers = async (data: any) => {
  const response = await apiWithAuth.post("/customers", data);
  return response.data.data;
};

export const getCustomers = async () => {
  const response = await apiWithAuth.get("/customers");
  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to fetch customers");
  }
  return response.data.data;
};

import React from "react";
import { Box } from "@chakra-ui/react";
import MDEditor from "@uiw/react-md-editor";

interface MarkDownEditorProps {
  value: string;
  setValue: (value: string) => void;
  height?: string; // Added height prop
}

const MarkDownEditor: React.FC<MarkDownEditorProps> = ({
  value,
  setValue,
  height = "400px",
}) => {
  // Default height
  return (
    <Box width="100%" margin={"auto"}>
      <Box width={"100%"} height={"100%"} data-color-mode="light">
        <MDEditor
          value={value}
          onChange={(value) => setValue(value || "")}
          height={height} // Use height prop
        />
      </Box>
    </Box>
  );
};

export default MarkDownEditor;

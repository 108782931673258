import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { authenticateGoogle } from "../../api/externalAuth";
import { useToast } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

function GoogleAuthentication() {
  const toast = useToast();

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      if (codeResponse.code) {
        try {
          await authenticateGoogle(codeResponse.code, "123");
          toast({
            title: "Success",
            description: "Google authentication successful",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error("Authentication error:", error);
          toast({
            title: "Error",
            description: "Failed to authenticate with the server",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
    onError: (errorResponse) => {
      console.error("Login Failed:", errorResponse);
      toast({
        title: "Error",
        description: "Google login failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    scope: "openid email profile https://www.googleapis.com/auth/calendar",
  });

  return (
    <div>
      <h1>Connect Your Google Account</h1>
      <Button onClick={() => login()}>Sign in with Google</Button>
    </div>
  );
}

export default GoogleAuthentication;

import { api } from "../apiService";

export enum AIType {
  GPT = "gpt",
  CLAUDE = "claude",
}

interface ChatMessage {
  sender: string;
  content: string;
}

interface ChatRequest {
  history: ChatMessage[];
  query: string;
  context: string;
  aiType: AIType;
  aiPower: string;
}

export const sendChatMessage = async (chatRequest: ChatRequest) => {
  const response = await api.post("/chat", chatRequest);
  return response.data;
};

export const sendConversationChatMessage = async (
  conversationId: string,
  chatRequest: ChatRequest
) => {
  const response = await api.put(`/chats/${conversationId}`, chatRequest);
  return response.data;
};

export const sendNewChatMessage = async (chatRequest: ChatRequest) => {
  const response = await api.post("/chats", chatRequest);
  return response.data;
};

export const getChatMessages = async (conversationId: string) => {
  const response = await api.get(`/chats/${conversationId}`);
  return response.data;
};

export const getChats = async () => {
  const response = await api.get("/chats");
  return response.data;
};

export const deleteChat = async (conversationId: string) => {
  const response = await api.delete(`/chats/${conversationId}`);
  return response.data;
};

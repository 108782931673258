import React from "react";
import { HStack, Text, Switch } from "@chakra-ui/react";
import { NodeData } from "../type";

interface WebSearchSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const WebSearchSection: React.FC<WebSearchSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  return (
    <>
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="bold" as="p">
          Allow Web Search:
        </Text>
        <Switch
          isChecked={nodeData.allowWebSearch}
          onChange={() =>
            setNodeData((prevState) => ({
              ...prevState,
              allowWebSearch: !prevState.allowWebSearch,
            }))
          }
        />
      </HStack>
      <Text>
        This will allow the Agent to go on the web to search about query.
      </Text>
    </>
  );
};

export default WebSearchSection;

import React from "react";
import {
  Box,
  VStack,
  Icon,
  Text,
  Flex,
  Divider,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBook,
  FiTarget,
  FiUsers,
  FiBarChart2,
  FiPhoneCall,
  FiMessageSquare,
  FiSettings,
  FiTrello,
  FiChevronLeft,
  FiChevronRight,
  FiBriefcase,
} from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import { useSidebar } from "../../contexts/SidebarContext";

const SideNav: React.FC = () => {
  const location = useLocation();
  const { isExpanded, toggleSidebar } = useSidebar();

  const isExactPath = (path: string) => location.pathname === path;

  return (
    <Box
      width={isExpanded ? "240px" : "60px"}
      bg="white"
      boxShadow="md"
      height="100%"
      py={6}
      display="flex"
      flexDirection="column"
      transition="width 0.3s"
    >
      <VStack spacing={4} align="stretch" flex={1}>
        <NavSection
          title="Company"
          isExpanded={isExpanded}
          toggleSidebar={toggleSidebar}
        >
          <NavLink to="/dashboard" end>
            {() => (
              <NavItem
                icon={FiHome}
                label="Dashboard"
                isActive={isExactPath("/dashboard")}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/campaigns"}>
            {({ isActive }) => (
              <NavItem
                icon={FiTarget}
                label="Campaigns"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/contacts"}>
            {({ isActive }) => (
              <NavItem
                icon={FiPhoneCall}
                label="Contacts"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/analytics"}>
            {({ isActive }) => (
              <NavItem
                icon={FiBarChart2}
                label="Analytics"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>

        <Divider my={4} />

        <NavSection title="Agents" isExpanded={isExpanded}>
          <NavLink to={"/dashboard/agents"}>
            {({ isActive }) => (
              <NavItem
                icon={FiUsers}
                label="Pathways"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/knowledge-base"}>
            {({ isActive }) => (
              <NavItem
                icon={FiBook}
                label="Knowledge Bases"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/voices"}>
            {({ isActive }) => (
              <NavItem
                icon={FiTrello}
                label="Voices"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to={"/dashboard/workflows"}>
            {({ isActive }) => (
              <NavItem
                icon={FiBriefcase}
                label="Workflows"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>

        <Divider my={4} />

        <NavSection title="Enterprise Chat" isExpanded={isExpanded}>
          <NavLink to={"/dashboard/chats"}>
            {({ isActive }) => (
              <NavItem
                icon={FiMessageSquare}
                label="Chats"
                isActive={isActive}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>
      </VStack>

      <Box mt="auto">
        <NavLink to={"/dashboard/settings"}>
          {({ isActive }) => (
            <NavItem
              icon={FiSettings}
              label="Settings"
              isActive={isActive}
              isExpanded={isExpanded}
            />
          )}
        </NavLink>
      </Box>
    </Box>
  );
};

const NavSection: React.FC<{
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
  toggleSidebar?: () => void;
}> = ({ title, children, isExpanded, toggleSidebar }) => (
  <Box>
    {isExpanded ? (
      <Flex alignItems="center" justifyContent="space-between" mb={2} px={6}>
        <Text fontSize="sm" fontWeight="bold" color="gray.500">
          {title}
        </Text>
        {toggleSidebar && (
          <IconButton
            aria-label="Collapse sidebar"
            icon={<FiChevronLeft />}
            onClick={toggleSidebar}
            size="sm"
            variant="ghost"
          />
        )}
      </Flex>
    ) : (
      toggleSidebar && (
        <Tooltip label="Expand" placement="right" hasArrow>
          <IconButton
            aria-label="Expand"
            icon={<FiChevronRight />}
            onClick={toggleSidebar}
            size="sm"
            variant="ghost"
            width="100%"
            justifyContent="center"
            borderRadius={0}
          />
        </Tooltip>
      )
    )}
    <VStack spacing={1} align="stretch">
      {children}
    </VStack>
  </Box>
);

const NavItem: React.FC<{
  icon: any;
  label: string;
  isActive: boolean;
  isExpanded: boolean;
}> = ({ icon, label, isActive, isExpanded }) => (
  <Tooltip label={isExpanded ? "" : label} placement="right" hasArrow>
    <Flex
      align="center"
      px={3}
      py={2}
      cursor="pointer"
      bg={isActive ? "blue.50" : "transparent"}
      color={isActive ? "blue.500" : "inherit"}
      fontWeight={isActive ? "bold" : "normal"}
      _hover={{ bg: isActive ? "blue.50" : "gray.100" }}
      justifyContent={isExpanded ? "flex-start" : "center"}
    >
      <Icon as={icon} boxSize={5} />
      {isExpanded && (
        <Text fontSize="sm" ml={4}>
          {label}
        </Text>
      )}
    </Flex>
  </Tooltip>
);

export default SideNav;

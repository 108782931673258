import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  Text,
  useToast,
  Spinner,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Icon,
  Card,
  CardBody,
  Flex,
} from "@chakra-ui/react";
import { FiUpload, FiUsers, FiFile, FiCheck } from "react-icons/fi";
import {
  createCustomers,
  getCustomers,
  uploadCustomerFile,
} from "../../api/customers";
import Papa from "papaparse";
import { validateCSVData, CSVRow } from "../../utils/csvValidation";

interface Customer {
  customer_id: number;
  customer_name: string;
  phone_number: string;
  email: string;
  additional_info: Record<string, any>;
  source: string;
  created_at: string;
}

const Contacts: React.FC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const data = await getCustomers();
      setCustomers(data);
    } catch (error) {
      toast({
        title: "Error fetching customers",
        description:
          error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Parse and validate CSV
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const validation = validateCSVData(
            results as Papa.ParseResult<CSVRow>
          );

          if (!validation.isValid) {
            setSelectedFile(null);
            toast({
              title: "Invalid CSV File",
              description: validation.error,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
          } else {
            setSelectedFile(file);
            toast({
              title: "File Validated Successfully",
              description: `CSV file contains ${results.data.length} valid records.`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        error: (error) => {
          setSelectedFile(null);
          toast({
            title: "Error Reading CSV File",
            description: "Please ensure your file is a valid CSV file.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      });
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsUploading(true);
    try {
      const result = await uploadCustomerFile(selectedFile);
      toast({
        title: "Upload Successful",
        description: "Contacts data has been uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await createCustomers({ fileUrl: result.fileUrl });

      await uploadCustomerFile(selectedFile);
      toast({
        title: "Contacts Created",
        description: "Customer data has been created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCustomers(); // Refresh the customer list
      onClose();
      setSelectedFile(null);
    } catch (error) {
      toast({
        title: "Upload Failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const formatDate = (timestamp: string) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleString();
  };

  return (
    <Container maxW="container.xl" py={8}>
      {/* Header Section */}
      <VStack align="stretch" spacing={8}>
        <Flex justify="space-between" align="center">
          <VStack align="start" spacing={1}>
            <Heading size="lg">Contacts</Heading>
            <Text color="gray.600">Manage your customer contacts</Text>
          </VStack>
          <Button
            leftIcon={<Icon as={FiUpload} />}
            colorScheme="blue"
            onClick={onOpen}
          >
            Upload Contacts
          </Button>
        </Flex>

        {/* Stats Cards */}
        <HStack spacing={4}>
          <Card flex={1}>
            <CardBody>
              <VStack align="start">
                <HStack spacing={2}>
                  <Icon as={FiUsers} color="blue.500" boxSize={5} />
                  <Text fontSize="lg" fontWeight="medium">
                    Total Contacts
                  </Text>
                </HStack>
                <Text fontSize="2xl" fontWeight="bold">
                  {customers.length}
                </Text>
              </VStack>
            </CardBody>
          </Card>
          {/* Add more stat cards as needed */}
        </HStack>

        {/* Customers Table */}
        <Card>
          <CardBody>
            {isLoading ? (
              <Flex justify="center" align="center" h="200px">
                <Spinner size="xl" />
              </Flex>
            ) : (
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Phone</Th>
                      <Th>Email</Th>
                      <Th>Source</Th>
                      <Th>Additional Info</Th>
                      <Th>Created At</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {customers.map((customer) => (
                      <Tr key={customer.customer_id}>
                        <Td fontWeight="medium">{customer.customer_name}</Td>
                        <Td>{customer.phone_number}</Td>
                        <Td>{customer.email || "-"}</Td>
                        <Td>
                          <Badge colorScheme="blue" variant="subtle">
                            {customer.source.includes("csv")
                              ? "CSV Upload"
                              : "Manual Entry"}
                          </Badge>
                        </Td>
                        <Td>
                          <pre>
                            {JSON.stringify(
                              customer.additional_info,
                              null,
                              2
                            ) || "-"}
                          </pre>
                        </Td>
                        <Td>{formatDate(customer.created_at)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </CardBody>
        </Card>
      </VStack>

      {/* Upload Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Contacts</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text color="gray.600">
                Upload a CSV file containing customer data. The file must
                include 'customer_name' and 'phone_number' columns.
              </Text>
              <Box
                borderWidth={2}
                borderRadius="md"
                borderStyle="dashed"
                p={6}
                textAlign="center"
                bg="gray.50"
              >
                {selectedFile ? (
                  <VStack spacing={2}>
                    <Icon as={FiCheck} color="green.500" boxSize={6} />
                    <Text fontWeight="medium">{selectedFile.name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {(selectedFile.size / 1024).toFixed(2)} KB
                    </Text>
                  </VStack>
                ) : (
                  <VStack spacing={2}>
                    <Icon as={FiFile} color="gray.400" boxSize={6} />
                    <Text fontWeight="medium">Drop your file here or</Text>
                    <Button
                      as="label"
                      size="sm"
                      colorScheme="blue"
                      cursor="pointer"
                    >
                      Browse
                      <Input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={handleFileSelect}
                      />
                    </Button>
                  </VStack>
                )}
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              isDisabled={isUploading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleUpload}
              isLoading={isUploading}
              loadingText="Uploading..."
              isDisabled={!selectedFile}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Contacts;

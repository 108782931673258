import { useState, useCallback, useEffect } from 'react';
import { RetellWebClient } from "retell-client-js-sdk";
import { GraphType, registerCallDemo } from "../../api/voice";

const useRetellWebCall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const [retellWebClient] = useState(() => new RetellWebClient());

  const initiateCall = useCallback(async (configuration?: Record<string, any>, graphType?: GraphType, voiceId?: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await registerCallDemo(configuration, graphType, voiceId);
      await retellWebClient.startCall({
        accessToken: response.access_token,
      });
      setIsCallActive(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  }, [retellWebClient]);

  const stopCall = useCallback(() => {
    retellWebClient.stopCall();
    setIsCallActive(false);
  }, [retellWebClient]);

  useEffect(() => {
    retellWebClient.on("call_started", () => {
      console.log("call started");
    });

    retellWebClient.on("call_ended", () => {
      console.log("call ended");
      setIsCallActive(false);
    });

    retellWebClient.on("agent_start_talking", () => {
      console.log("agent_start_talking");
    });

    retellWebClient.on("agent_stop_talking", () => {
      console.log("agent_stop_talking");
    });

    retellWebClient.on("update", (update: any) => {
      console.log("Transcript:", update.transcript);
    });

    retellWebClient.on("error", (error: any) => {
      console.error("An error occurred:", error);
      setError("An error occurred during the call");
      stopCall();
    });

    return () => {
      retellWebClient.removeAllListeners();
    };
  }, [retellWebClient, stopCall]);

  return {
    initiateCall,
    stopCall,
    isLoading,
    error,
    isCallActive
  };
};

export default useRetellWebCall;

import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  ButtonGroup,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import {
  getKnowledgeBaseMetadata,
  updateKnowledgeBase,
} from "../../api/knowledgebase";

type KnowledgeBaseType = "graph" | "vector";

const EditKnowledgeBase: React.FC = () => {
  const { knowledgeBaseId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<
    Array<{ name: string; size: number }>
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [description, setDescription] = useState("");
  const [knowledgeBaseType, setKnowledgeBaseType] =
    useState<KnowledgeBaseType>("vector");

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      try {
        const organizationId = "1"; // TODO: get organizationId from user
        if (!knowledgeBaseId) return;
        const data = await getKnowledgeBaseMetadata(
          organizationId,
          knowledgeBaseId
        );
        setTitle(data.name);
        setDescription(data.description || "");
        setKnowledgeBaseType(data.knowledgeBaseType);

        const fileObjects = data.s3_uris.map((uri: string) => ({
          name: uri.split("/").pop() || uri,
          size: 0,
        }));
        setExistingFiles(fileObjects);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch knowledge base details",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        navigate("/dashboard/knowledge-base");
      } finally {
        setIsFetching(false);
      }
    };

    fetchKnowledgeBase();
  }, [knowledgeBaseId, toast, navigate]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
    },
  });

  const removeFile = (indexToRemove: number) => {
    setFiles((prev) => prev.filter((_, index) => index !== indexToRemove));
  };

  const removeExistingFile = (indexToRemove: number) => {
    setExistingFiles((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!title.trim()) {
        toast({
          title: "Error",
          description: "Please enter a title",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!knowledgeBaseId) {
        throw new Error("Knowledge base ID is required");
      }

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("organizationId", "1"); // TODO: get from user context
      formData.append("knowledgeBaseId", knowledgeBaseId);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("knowledgeBaseType", knowledgeBaseType);

      const existingFileNames = existingFiles.map((file) => file.name);
      formData.append("existingFileNames", JSON.stringify(existingFileNames));

      const response = await updateKnowledgeBase(formData);

      toast({
        title: "Success",
        description: response.message || "Knowledge base updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/dashboard/knowledge-base");
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to update knowledge base",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetching) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box height="100%" p={4}>
      <Text fontSize="2xl" mb={6}>
        Edit Knowledge Base
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={6} align="stretch">
          <FormControl isRequired>
            <FormLabel>Knowledge Base Type</FormLabel>
            <ButtonGroup isAttached width="100%">
              <Button
                flex={1}
                onClick={() => setKnowledgeBaseType("vector")}
                colorScheme={knowledgeBaseType === "vector" ? "blue" : "gray"}
                variant={knowledgeBaseType === "vector" ? "solid" : "outline"}
                type="button"
              >
                Direct
              </Button>
              <Tooltip
                label="Relational knowledge bases are a premium feature with additional costs"
                placement="top"
                hasArrow
              >
                <Button
                  flex={1}
                  onClick={() => setKnowledgeBaseType("graph")}
                  colorScheme={knowledgeBaseType === "graph" ? "blue" : "gray"}
                  variant={knowledgeBaseType === "graph" ? "solid" : "outline"}
                  type="button"
                >
                  Relational
                </Button>
              </Tooltip>
            </ButtonGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter knowledge base title"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter knowledge base description"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Upload Additional Files</FormLabel>
            <Box
              {...getRootProps()}
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              borderColor={isDragActive ? "blue.500" : "gray.200"}
              p={6}
              textAlign="center"
              bg={isDragActive ? "gray.50" : "white"}
              cursor="pointer"
              transition="all 0.2s"
              _hover={{
                borderColor: "blue.500",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text>Drop the files here...</Text>
              ) : (
                <Text>Drag and drop files here, or click to select files</Text>
              )}
              <Text fontSize="sm" color="gray.500" mt={2}>
                Supported formats: PDF, DOC, DOCX, TXT, CSV
              </Text>
            </Box>
          </FormControl>

          {existingFiles.length > 0 && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                Existing Files:
              </Text>
              <VStack align="stretch" spacing={2}>
                {existingFiles.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <Text fontSize="sm">
                      {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                    </Text>
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => removeExistingFile(index)}
                    >
                      ✕
                    </Button>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}

          {files.length > 0 && (
            <Box>
              <Text fontWeight="bold" mb={2}>
                New Files to Add:
              </Text>
              <VStack align="stretch" spacing={2}>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <Text fontSize="sm">
                      {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                    </Text>
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => removeFile(index)}
                    >
                      ✕
                    </Button>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}

          <Button
            type="submit"
            colorScheme="blue"
            isLoading={isLoading}
            loadingText="Updating..."
          >
            Update Knowledge Base
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default EditKnowledgeBase;

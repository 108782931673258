import React from "react";
import { HStack, Text, Switch, Input } from "@chakra-ui/react";
import { NodeData } from "../type";

interface GlobalNodeSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const GlobalNodeSection: React.FC<GlobalNodeSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  return (
    <>
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="bold" as="p">
          Global Node:
        </Text>
        <Switch
          isChecked={nodeData.globalNodeObject.globalNode}
          onChange={() =>
            setNodeData((prev) => ({
              ...nodeData,
              globalNodeObject: {
                ...nodeData.globalNodeObject,
                globalNode: !prev.globalNodeObject.globalNode,
              },
            }))
          }
        />
      </HStack>
      <Text>
        Toggle this if you want to make this node accessible from any other
        node. Global nodes have a hidden pathway that links all other nodes, to
        this node.
      </Text>
      <Text mt={2} fontWeight={500}>
        Global Node Pathway Label (Condition to path to this node)
      </Text>
      <Input
        placeholder="Global Edge Condition"
        value={nodeData.globalNodeObject.globalEdgeCondition}
        onChange={(e) =>
          setNodeData({
            ...nodeData,
            globalNodeObject: {
              ...nodeData.globalNodeObject,
              globalEdgeCondition: e.target.value,
            },
          })
        }
      />
      <Text mt={2} fontWeight={500}>
        Global Node Pathway Description (Additional Description for when to
        choose this pathway)
      </Text>
      <Input
        placeholder="Global Edge Description"
        value={nodeData.globalNodeObject.globalEdgeDescription}
        onChange={(e) =>
          setNodeData({
            ...nodeData,
            globalNodeObject: {
              ...nodeData.globalNodeObject,
              globalEdgeDescription: e.target.value,
            },
          })
        }
      />
    </>
  );
};

export default GlobalNodeSection;

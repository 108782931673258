import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  useToast,
  HStack,
  IconButton,
  VStack,
  Spinner,
  Divider,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import axios from "axios";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteVoiceConfiguration,
  getVoiceConfigurations,
} from "../../api/voice";
import { VoiceListConfig } from "./type";

const Voices: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [voices, setVoices] = useState<VoiceListConfig[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getVoices();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getVoices = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getVoiceConfigurations();
      setVoices(response);
      console.log(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteVoice = useCallback(async (voiceId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteVoiceConfiguration(voiceId);
        getVoices();
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreateAgent = useCallback(() => {
    navigate("/dashboard/voice/new");
  }, [navigate]);

  const handleEditAgent = useCallback(
    (agentId: string) => {
      navigate(`/dashboard/voice/edit/${agentId}`);
    },
    [navigate]
  );

  const filteredVoices = voices.filter((voice) =>
    (voice.agent_name || voice.agent_id)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Box height={"100%"}>
      <HStack mb={5}>
        <Text as="h1" fontSize="2xl">
          Voices
        </Text>
      </HStack>
      <HStack mb={4}>
        <Button
          size="sm"
          variant="outline"
          colorScheme="teal"
          aria-label="Create Agent"
          onClick={handleCreateAgent}
        >
          + Voice Configuration
        </Button>
      </HStack>

      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.400" />
        </InputLeftElement>
        <Input
          placeholder="Search voices..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      {isLoading ? (
        <Spinner mt={5} />
      ) : (
        <Box>
          <HStack spacing={4} wrap="wrap">
            {filteredVoices.map((voice) => (
              <Box
                key={voice.agent_id}
                bgColor="gray.100"
                borderRadius={5}
                p={2}
                my={2}
                height={180}
                width={{ base: "100%", sm: "48%", md: "30%" }} // Adjust width for grid
              >
                <VStack
                  alignItems={"start"}
                  justifyContent={"space-between"}
                  height={"100%"}
                >
                  <VStack
                    alignItems={"start"}
                    width={"90%"}
                    overflow={"hidden"}
                  >
                    <Text fontSize={"lg"}>
                      {voice.agent_name || voice.agent_id}
                    </Text>
                  </VStack>

                  <Box width={"100%"}>
                    <Divider borderColor={"black"} />
                    <HStack justifyContent="space-between" mt={2}>
                      <HStack>
                        <Text>{voice.last_modification_timestamp}</Text>
                      </HStack>
                      <HStack>
                        <IconButton
                          size="sm"
                          variant="outline"
                          colorScheme="teal"
                          aria-label="Edit Agent"
                          icon={<EditIcon />}
                          onClick={() => handleEditAgent(voice.agent_id)}
                        />
                        <IconButton
                          size="sm"
                          variant="outline"
                          colorScheme="red"
                          aria-label="Delete Agent"
                          icon={<DeleteIcon />}
                          onClick={() => deleteVoice(voice.agent_id)}
                        />
                      </HStack>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            ))}
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default Voices;

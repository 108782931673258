import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Textarea,
  Button,
  useToast,
} from '@chakra-ui/react';
import { promptImprovementPrompt as defaultPromptImprovementPrompt } from "../../constants/prompts/prompt_improvement";
import GoogleAuthentication from "../../components/GoogleAuthentication";

const Settings = () => {
  const [customPrompt, setCustomPrompt] = useState('');
  const toast = useToast();

  useEffect(() => {
    const savedPrompt = localStorage.getItem('customPromptImprovementPrompt');
    if (savedPrompt) {
      setCustomPrompt(savedPrompt);
    } else {
      setCustomPrompt(defaultPromptImprovementPrompt);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem('customPromptImprovementPrompt', customPrompt);
    toast({
      title: 'Settings saved',
      description: 'Your custom prompt improvement prompt has been saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleReset = () => {
    setCustomPrompt(defaultPromptImprovementPrompt);
    localStorage.removeItem('customPromptImprovementPrompt');
    toast({
      title: 'Settings reset',
      description: 'Prompt improvement prompt has been reset to default.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box p={5}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="xl">Settings</Heading>

        <Box>
          <Text mb={2} fontWeight="bold">Custom Prompt Improvement Prompt:</Text>
          <Textarea
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            placeholder="Enter your custom prompt improvement prompt"
            size="lg"
            rows={10}
          />
        </Box>

        <Box>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save Changes
          </Button>
          <Button variant="outline" onClick={handleReset}>
            Reset to Default
          </Button>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>Google Authentication</Heading>
          <Box borderWidth={1} borderRadius="lg" p={4}>
            <GoogleAuthentication />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default Settings;

export const BASE_URL =
  process.env.REACT_APP_ENV === "alpha"
    ? "https://api.alpha.agnostech.ai"
    : process.env.REACT_APP_ENV === "local"
    ? "http://localhost:8080"
    : "https://api.agnostech.ai";
export const BASE_WS_URL =
  process.env.REACT_APP_ENV === "alpha"
    ? "wss://orch.alpha.agnostech.ai"
    : process.env.REACT_APP_ENV === "local"
    ? "ws://localhost:8001"
    : "wss://orch.agnostech.ai";

export const COMMN_WS_URL =
  process.env.REACT_APP_ENV === "alpha" || process.env.REACT_APP_ENV === "local"
    ? "wss://commn.alpha.agnostech.ai"
    : "wss://commn.agnostech.ai";

import React from "react";
import {
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { NodeData } from "../type";

interface AdvancedOptionsSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const modelTypes = [
  { id: 0, name: "GPT-4o", value: "gpt-4o" },
  { id: 1, name: "GPT-4o-mini", value: "gpt-4o-mini" },
];

const AdvancedOptionsSection: React.FC<AdvancedOptionsSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  return (
    <>
      <Text fontSize="lg" fontWeight="bold" as="p">
        Advanced Options:
      </Text>
      <Text>
        Adjust settings to optimize model performance (Temperature, Latency, and
        Model Intelligence)
      </Text>
      <Select
        placeholder="Select Option"
        value={nodeData.advancedOptionObject.modelType}
        onChange={(e) => {
          setNodeData({
            ...nodeData,
            advancedOptionObject: {
              ...nodeData.advancedOptionObject,
              modelType: e.target.value,
            },
          });
        }}
      >
        {modelTypes.map((model) => (
          <option key={model.id} value={model.value}>
            {model.name}
          </option>
        ))}
      </Select>
      <Text mt={2}>Temperature Between 0.0 to 1.0</Text>
      <NumberInput
        value={nodeData.advancedOptionObject.temperature}
        onChange={(value) =>
          setNodeData({
            ...nodeData,
            advancedOptionObject: {
              ...nodeData.advancedOptionObject,
              temperature: Number(value),
            },
          })
        }
        min={0}
        max={1}
        step={0.1}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default AdvancedOptionsSection;

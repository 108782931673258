import {
  MdOutlineWifiCalling3,
  MdOutlineCollectionsBookmark,
  MdOutlineCallEnd,
  MdOutlineWebhook,
  MdOutlineSms,
} from "react-icons/md";
import { BiTransfer, BiLoaderCircle } from "react-icons/bi";

import { ListNodeTypes } from "./type";

export const availableNodeTypes: ListNodeTypes[] = [
  {
    id: 0,
    icon: MdOutlineWifiCalling3,
    type: "DefaultNode",
    name: "Default",
    isDisabled: false,
    info: "This is the default node",
  },
  {
    id: 1,
    icon: MdOutlineCollectionsBookmark,
    type: "KnowledgeBaseNode",
    name: "Knowledge Base",
    isDisabled: false,
    info: "Define knowledge base for agents",
  },
  {
    id: 2,
    icon: MdOutlineWebhook,
    type: "ToolNode",
    name: "Tool",
    isDisabled: false,
    info: "Integrate tool for api calling",
  },

  {
    id: 3,
    icon: MdOutlineCallEnd,
    type: "EndCallNode",
    name: "End Call",
    isDisabled: false,
    info: "Agent terminal state",
  },
  {
    id: 4,
    icon: BiTransfer,
    type: "TransferCallNode",
    name: "Transfer Call",
    isDisabled: true,
    info: "WIP",
  },
  {
    id: 5,
    icon: BiLoaderCircle,
    type: "WaitForResponseNode",
    name: "Wait For Response",
    isDisabled: true,
    info: "WIP",
  },
  {
    id: 6,
    icon: MdOutlineSms,
    type: "SMSNode",
    name: "SMS Handoff",
    isDisabled: false,
    info: "Have your agent handoff to an SMS conversation",
  },
];

import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Text,
  IconButton,
  Collapse,
  HStack,
  Tooltip,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
import {
  Outlet,
  useLocation,
  NavLink,
  useParams,
  useNavigate,
} from "react-router-dom"; // Replaced useParams with NavLink

import { getChats, deleteChat } from "../../api/chat";
import ChatNew from "./ChatNew";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";

const ChatHistory: React.FC = () => {
  const location = useLocation();
  const { conversationId: currentPageConversationId } = useParams();
  const navigate = useNavigate();
  const hasSubRoute = location.pathname !== "/dashboard/chats";

  const [isLoading, setIsLoading] = useState(false);
  const [chatsHistory, setChatsHistory] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [aiPower, setAiPower] = useState("high");
  const [llmType, setLlmType] = useState("openai");
  const [context, setContext] = useState("You are a helpful assistant.");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getChatsHistory();
    // The effect will run whenever the location changes
  }, [location]);

  const getChatsHistory = async () => {
    try {
      setIsLoading(true);
      const response = await getChats();
      setChatsHistory(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConversation = async (conversationId: string) => {
    if (window.confirm("Are you sure you want to delete this conversation?")) {
      await deleteChat(conversationId);
      if (conversationId === currentPageConversationId) {
        navigate("/dashboard/chats");
      } else {
        getChatsHistory();
      }
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={4}
      width={"100%"}
      height={"100%"}
    >
      <Box
        p={4}
        display={isCollapsed ? "none" : "block"}
        width={isCollapsed ? "0" : "300px"}
        height={"100%"}
        overflowY={"auto"}
        transition="width 0.3s"
      >
        <Box display="flex" alignItems="center" mb={4}>
          <Text fontSize={"xl"} fontWeight={"bold"} flex="1">
            Chat History
          </Text>
        </Box>
        <Collapse in={!isCollapsed} animateOpacity>
          {isCollapsed ? null : (
            <>
              <Divider mb={4} />

              {isLoading && chatsHistory.length === 0 && (
                <Text>Loading chats...</Text>
              )}

              {!isLoading && chatsHistory.length === 0 && (
                <Text>No chats history</Text>
              )}

              {chatsHistory.map((chat) => (
                <NavLink
                  key={chat.conversationId}
                  to={`/dashboard/chats/${chat.conversationId}`}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? "#E2E8F0" : "transparent",
                    color: isActive ? "blue.500" : "gray.500",
                    border: "1px solid #E2E8F0",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    display: "block",
                    marginBottom: "5px",
                  })}
                >
                  <HStack>
                    <Tooltip label={chat.topic}>
                      <Text
                        _hover={{ cursor: "pointer", color: "blue.500" }}
                        isTruncated
                        maxWidth="auto"
                      >
                        {chat.topic}
                      </Text>
                    </Tooltip>

                    <IconButton
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteConversation(chat.conversationId);
                      }}
                    />
                  </HStack>
                </NavLink>
              ))}
            </>
          )}
        </Collapse>
      </Box>
      <Box
        width={"100%"}
        height={"100%"}
        borderLeft={"1px solid"}
        borderColor={isCollapsed ? "transparent" : "gray.200"}
        overflowY={"auto"}
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          mb={4}
          position="absolute"
          top="0"
          left="0"
          zIndex="1000"
          gap={2}
        >
          <Box>
            <Tooltip
              label={
                isCollapsed ? "Expand chat history" : "Collapse chat history"
              }
              placement="right"
            >
              <IconButton
                aria-label={
                  isCollapsed ? "Expand chat history" : "Collapse chat history"
                }
                icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                onClick={toggleCollapse}
                size="sm"
                ml={4}
              />
            </Tooltip>
          </Box>
          <Box>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Settings"
                icon={<ChevronDownIcon />}
                variant="outline"
                size="sm"
                bg="gray.100"
                _hover={{
                  width: "auto",
                  transition: "width 0.2s",
                  "& > span": { display: "inline" },
                }}
              >
                <Text as="span" display="none" ml={2} verticalAlign="middle">
                  Settings
                </Text>
              </MenuButton>
              <MenuList p={4}>
                <MenuItem _hover={{}} _focus={{}}>
                  <Flex alignItems="center" width="100%">
                    <Text fontWeight="bold" width="100px">
                      AI Power
                    </Text>
                    <Flex ml={4}>
                      {["Low", "Medium", "High"].map((power) => (
                        <Button
                          key={power}
                          size="sm"
                          variant={
                            aiPower === power.toLowerCase()
                              ? "solid"
                              : "outline"
                          }
                          onClick={() => setAiPower(power.toLowerCase())}
                          mr={2}
                        >
                          {power}
                        </Button>
                      ))}
                    </Flex>
                  </Flex>
                </MenuItem>
                <MenuItem mt={4} _hover={{}} _focus={{}}>
                  <Flex alignItems="center" width="100%">
                    <Text fontWeight="bold" width="100px">
                      LLM Type
                    </Text>
                    <Flex ml={4}>
                      {["Openai", "Claude"].map((type) => (
                        <Button
                          key={type}
                          size="sm"
                          variant={
                            llmType === type.toLowerCase() ? "solid" : "outline"
                          }
                          onClick={() => setLlmType(type.toLowerCase())}
                          mr={2}
                        >
                          {type}
                        </Button>
                      ))}
                    </Flex>
                  </Flex>
                </MenuItem>
                <MenuItem mt={4} _hover={{}} _focus={{}}>
                  <Flex alignItems="center" width="100%">
                    <Text fontWeight="bold" width="100px">
                      Context
                    </Text>
                    <Button
                      leftIcon={<EditIcon />}
                      onClick={onOpen}
                      ml={4}
                      size="sm"
                    >
                      Edit
                    </Button>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
        <>
          {hasSubRoute ? (
            <Outlet context={{ aiPower, llmType, context }} />
          ) : (
            <ChatNew aiPower={aiPower} llmType={llmType} context={context} />
          )}
        </>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Chat Context</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={context}
              onChange={(e) => setContext(e.target.value)}
              placeholder="Enter the context for the chat (system prompt)"
              size="sm"
              resize="vertical"
              minHeight="150px"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ChatHistory;

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Spinner,
  Text,
  HStack,
  useToast,
  VStack,
  Flex,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Avatar,
  AvatarBadge,
  Divider,
  Tag,
  TagLabel,
  TagLeftIcon,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getCampaignCustomers,
  CampaignCustomerDetails,
  getCampaignCustomerInteractions,
  getCallTranscriptions,
  CallInteraction,
  CallTranscription,
} from "../../api/campaigns";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PhoneIcon,
  EmailIcon,
  ViewIcon,
  ChatIcon,
  TimeIcon,
  InfoIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import { formatDate } from "../../utils/dateUtils";
import { BsChatDots, BsRobot, BsPerson } from "react-icons/bs";

interface InteractionDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId: number;
  customer: CampaignCustomerDetails;
}

const PhoneOutgoingIcon = () => (
  <Icon viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M20 15.5c-1.25 0-2.45-.2-3.57-.57a1.02 1.02 0 0 0-1.02.24l-2.2 2.2a15.045 15.045 0 0 1-6.59-6.59l2.2-2.21a.96.96 0 0 0 .25-1A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2V8h-2v4zm0-6h2V2h-2v4z"
    />
  </Icon>
);

const InteractionDrawer: React.FC<InteractionDrawerProps> = ({
  isOpen,
  onClose,
  campaignId,
  customer,
}) => {
  const [interactions, setInteractions] = useState<CallInteraction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
  const {
    isOpen: isTranscriptOpen,
    onOpen: onTranscriptOpen,
    onClose: onTranscriptClose,
  } = useDisclosure();
  const cardBg = useColorModeValue("blue.50", "blue.900");
  const titleColor = useColorModeValue("gray.700", "white");
  const overviewTitleColor = useColorModeValue("gray.600", "gray.200");
  const overviewTextColor = useColorModeValue("gray.700", "gray.100");
  const metadataColor = useColorModeValue("gray.500", "gray.400");

  const fetchInteractions = async () => {
    try {
      setIsLoading(true);
      const data = await getCampaignCustomerInteractions(
        campaignId,
        customer.campaign_customer_id
      );
      setInteractions(data);
    } catch (error) {
      console.error("Error fetching interactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchInteractions();
    }
  }, [isOpen, campaignId, customer.campaign_customer_id]);

  const handleTranscriptClick = (callId: number) => {
    setSelectedCallId(callId);
    onTranscriptOpen();
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <VStack align="start" spacing={2}>
              <Heading size="md">Interaction History</Heading>
              <HStack>
                <Text color="gray.600" fontSize="sm">
                  {customer.customer.customer_name}
                </Text>
                <Tag size="sm" colorScheme="blue" borderRadius="full">
                  <TagLeftIcon as={PhoneIcon} />
                  <TagLabel>{customer.customer.phone_number}</TagLabel>
                </Tag>
              </HStack>
            </VStack>
          </DrawerHeader>

          <DrawerBody bg={useColorModeValue("gray.50", "gray.800")}>
            {isLoading ? (
              <Flex justify="center" align="center" h="100%">
                <Spinner />
              </Flex>
            ) : (
              <VStack spacing={4} align="stretch">
                {interactions.map((interaction, index) => (
                  <Card key={interaction.call_id}>
                    <CardBody>
                      <VStack align="stretch" spacing={4}>
                        <Flex justify="space-between" align="center">
                          <HStack spacing={4}>
                            <Avatar
                              size="sm"
                              bg={
                                interaction.status === "COMPLETE"
                                  ? "green.500"
                                  : "gray.500"
                              }
                              icon={<PhoneOutgoingIcon />}
                            >
                              <AvatarBadge
                                boxSize="1.25em"
                                bg={
                                  interaction.result === "INTERESTED"
                                    ? "green.500"
                                    : "red.500"
                                }
                              />
                            </Avatar>
                            <VStack align="start" spacing={0}>
                              <Text fontWeight="medium">Call #{index + 1}</Text>
                              <Text fontSize="sm" color="gray.500">
                                {formatDate(interaction.created_at)}
                              </Text>
                            </VStack>
                          </HStack>
                          <Button
                            size="sm"
                            leftIcon={<BsChatDots />}
                            colorScheme="blue"
                            variant="outline"
                            onClick={() =>
                              handleTranscriptClick(interaction.call_id)
                            }
                          >
                            View Transcript
                          </Button>
                        </Flex>

                        <Divider />

                        <SimpleGrid columns={2} spacing={4}>
                          <HStack>
                            <TimeIcon color="gray.500" />
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm">
                                Duration: {interaction.call_duration}s
                              </Text>
                              {interaction.max_duration_exceeded && (
                                <Badge
                                  colorScheme="red"
                                  variant="subtle"
                                  size="sm"
                                  fontSize="xs"
                                >
                                  Max Duration Exceeded
                                </Badge>
                              )}
                            </VStack>
                          </HStack>
                          <HStack>
                            <InfoIcon color="gray.500" />
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm">
                                Status: {interaction.status}
                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                Result: {interaction.result}
                              </Text>
                            </VStack>
                          </HStack>
                          <HStack>
                            <PhoneIcon color="gray.500" />
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm">
                                Number: {interaction.campaign_number}
                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                Carrier: {interaction.carrier}
                              </Text>
                            </VStack>
                          </HStack>
                          <HStack>
                            <Icon as={BsRobot} color="gray.500" />
                            <VStack align="start" spacing={0}>
                              <Text fontSize="sm">
                                Agent: {interaction.conversation_agent_provider}
                              </Text>
                              <Text fontSize="xs" color="gray.500">
                                ID: {interaction.conversation_agent_call_id}
                              </Text>
                            </VStack>
                          </HStack>
                        </SimpleGrid>

                        {interaction.summary && (
                          <Box
                            bg={cardBg}
                            p={4}
                            borderRadius="md"
                            borderLeft="4px"
                            borderLeftColor={
                              interaction.summary.sentiment === "Interested"
                                ? "green.400"
                                : "red.400"
                            }
                          >
                            <VStack align="stretch" spacing={3}>
                              <Flex justify="space-between" align="center">
                                <HStack>
                                  <Icon
                                    as={
                                      interaction.summary.sentiment ===
                                      "Interested"
                                        ? CheckCircleIcon
                                        : InfoIcon
                                    }
                                    color={
                                      interaction.summary.sentiment ===
                                      "Interested"
                                        ? "green.500"
                                        : "red.500"
                                    }
                                    w={5}
                                    h={5}
                                  />
                                  <Text
                                    fontSize="md"
                                    fontWeight="semibold"
                                    color={titleColor}
                                  >
                                    Call Summary & Analysis
                                  </Text>
                                </HStack>
                                <Tag
                                  size="md"
                                  variant="subtle"
                                  colorScheme={
                                    interaction.summary.sentiment ===
                                    "Interested"
                                      ? "green"
                                      : "red"
                                  }
                                >
                                  {interaction.summary.sentiment}
                                </Tag>
                              </Flex>

                              <Divider />

                              <Box>
                                <Text
                                  fontSize="sm"
                                  fontWeight="medium"
                                  color={overviewTitleColor}
                                  mb={1}
                                >
                                  Conversation Overview
                                </Text>
                                <Text
                                  fontSize="sm"
                                  color={overviewTextColor}
                                  lineHeight="tall"
                                >
                                  {interaction.summary.summary_text}
                                </Text>
                              </Box>

                              <SimpleGrid columns={2} spacing={4} pt={2}>
                                <VStack align="start" spacing={1}>
                                  <Text fontSize="xs" color={metadataColor}>
                                    Summary Generated
                                  </Text>
                                  <Text fontSize="sm">
                                    {formatDate(interaction.summary.created_at)}
                                  </Text>
                                </VStack>
                                <VStack align="start" spacing={1}>
                                  <Text fontSize="xs" color={metadataColor}>
                                    Summary ID
                                  </Text>
                                  <Text fontSize="sm">
                                    #{interaction.summary.call_summary_id}
                                  </Text>
                                </VStack>
                              </SimpleGrid>
                            </VStack>
                          </Box>
                        )}
                      </VStack>
                    </CardBody>
                  </Card>
                ))}
              </VStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {selectedCallId && (
        <TranscriptionModal
          isOpen={isTranscriptOpen}
          onClose={onTranscriptClose}
          callId={selectedCallId}
        />
      )}
    </>
  );
};

interface TranscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  callId: number;
}

const TranscriptionModal: React.FC<TranscriptionModalProps> = ({
  isOpen,
  onClose,
  callId,
}) => {
  const [transcriptions, setTranscriptions] = useState<CallTranscription[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const modalBg = useColorModeValue("white", "gray.800");

  const fetchTranscriptions = async () => {
    try {
      setIsLoading(true);
      const data = await getCallTranscriptions(callId);
      setTranscriptions(data);
    } catch (error) {
      console.error("Error fetching transcriptions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchTranscriptions();
    }
  }, [isOpen, callId]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [transcriptions]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent maxH="80vh" h="80vh" display="flex" flexDirection="column">
        <ModalHeader
          borderBottomWidth="1px"
          bg={modalBg}
          position="sticky"
          top={0}
          zIndex={1}
          px={6}
          py={4}
          flex="0 0 auto"
        >
          <HStack>
            <ChatIcon />
            <Text>Call Transcript</Text>
            <Tag size="sm" colorScheme="blue" ml={2}>
              Call #{callId}
            </Tag>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody
          flex="1 1 auto"
          overflowY="auto"
          p={6}
          display="flex"
          flexDirection="column"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              width: "10px",
              background: useColorModeValue("gray.100", "gray.900"),
            },
            "&::-webkit-scrollbar-thumb": {
              background: useColorModeValue("gray.400", "gray.600"),
              borderRadius: "24px",
            },
            scrollBehavior: "smooth",
            scrollbarWidth: "thin",
            scrollbarColor: `${useColorModeValue(
              "rgba(0,0,0,0.3)",
              "rgba(255,255,255,0.3)"
            )} transparent`,
          }}
        >
          {isLoading ? (
            <Flex justify="center" align="center" h="200px">
              <Spinner />
            </Flex>
          ) : (
            <VStack spacing={4} align="stretch" flex="1">
              {transcriptions.map((transcript) => (
                <Flex
                  key={transcript.call_transcription_id}
                  justify={
                    transcript.message.role === "AI" ? "flex-start" : "flex-end"
                  }
                >
                  <Box
                    maxW="70%"
                    bg={
                      transcript.message.role === "AI" ? "blue.500" : "gray.100"
                    }
                    color={transcript.message.role === "AI" ? "white" : "black"}
                    p={4}
                    borderRadius="lg"
                    position="relative"
                    boxShadow="sm"
                    mx={2}
                  >
                    <HStack spacing={1} mb={1}>
                      <Avatar
                        size="2xs"
                        bg={
                          transcript.message.role === "AI"
                            ? "blue.600"
                            : "gray.500"
                        }
                        icon={
                          transcript.message.role === "AI" ? (
                            <BsRobot size={8} />
                          ) : (
                            <BsPerson size={8} />
                          )
                        }
                      />
                      <Text
                        fontSize="10px"
                        color={
                          transcript.message.role === "AI"
                            ? "whiteAlpha.800"
                            : "gray.600"
                        }
                        fontWeight="medium"
                      >
                        {transcript.message.role === "AI" ? "AI" : "Customer"}
                      </Text>
                    </HStack>
                    <Text fontSize="sm" lineHeight="tall">
                      {transcript.message.content}
                    </Text>
                    <Text
                      fontSize="xs"
                      color={
                        transcript.message.role === "AI"
                          ? "whiteAlpha.700"
                          : "gray.500"
                      }
                      mt={2}
                    >
                      {formatDate(transcript.created_at)}
                    </Text>
                  </Box>
                </Flex>
              ))}
              <div ref={bottomRef} />
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const CampaignCustomers: React.FC = () => {
  const { campaignId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [customers, setCustomers] = useState<CampaignCustomerDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10,
  });
  const toast = useToast();
  const [selectedCustomer, setSelectedCustomer] =
    useState<CampaignCustomerDetails | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const page = parseInt(searchParams.get("page") || "1");
  const limit = parseInt(searchParams.get("limit") || "10");

  useEffect(() => {
    if (page < 1 || limit < 1) {
      setSearchParams({ page: "1", limit: "10" });
      return;
    }
    fetchCustomers();
  }, [campaignId, page, limit]);

  const fetchCustomers = async () => {
    if (!campaignId) return;
    try {
      setIsLoading(true);
      const response = await getCampaignCustomers(
        parseInt(campaignId),
        page,
        limit
      );
      setCustomers(response.data);
      setPagination(response.pagination);

      if (response.pagination.pages > 0 && page > response.pagination.pages) {
        setSearchParams({
          page: response.pagination.pages.toString(),
          limit: limit.toString(),
        });
      }
    } catch (error) {
      console.error("Error fetching campaign customers:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to fetch customers",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toUpperCase()) {
      case "INTERESTED":
        return "green";
      case "NOT_INTERESTED":
        return "red";
      case "PENDING":
        return "yellow";
      case "IN_PROGRESS":
        return "blue";
      default:
        return "gray";
    }
  };

  const handlePageChange = (newPage: number) => {
    setSearchParams({
      page: newPage.toString(),
      limit: limit.toString(),
    });
  };

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const headerBg = useColorModeValue("gray.50", "gray.700");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  const handleViewInteractions = (customer: CampaignCustomerDetails) => {
    setSelectedCustomer(customer);
    onOpen();
  };

  if (isLoading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <VStack spacing={4}>
          <Spinner size="xl" thickness="4px" color="blue.500" />
          <Text color="gray.500">Loading customers...</Text>
        </VStack>
      </Flex>
    );
  }

  return (
    <Container maxW="container.xl">
      <VStack spacing={8} align="stretch">
        <Heading size="lg">Campaign Customers</Heading>

        <Card>
          <CardHeader
            bg={headerBg}
            borderBottom="1px"
            borderColor={borderColor}
            roundedTop="lg"
          >
            <Flex justify="space-between" align="center">
              <Text color="gray.500">
                Manage and monitor your campaign customers
              </Text>
              <Badge
                colorScheme="blue"
                fontSize="md"
                px={3}
                py={1}
                borderRadius="full"
              >
                Total: {pagination.total}
              </Badge>
            </Flex>
          </CardHeader>

          <CardBody p={0}>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead bg={headerBg}>
                  <Tr>
                    <Th borderColor={borderColor}>Customer Details</Th>
                    <Th borderColor={borderColor}>Contact Info</Th>
                    <Th borderColor={borderColor}>Status</Th>
                    <Th borderColor={borderColor}>Attempts</Th>
                    <Th borderColor={borderColor}>Next Contact</Th>
                    <Th borderColor={borderColor}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {customers.map((customer) => (
                    <Tr
                      key={customer.campaign_customer_id}
                      _hover={{ bg: hoverBg }}
                      transition="background 0.2s"
                    >
                      <Td borderColor={borderColor}>
                        <VStack align="start" spacing={1}>
                          <Text fontWeight="medium">
                            {customer.customer.customer_name}
                          </Text>
                          <Text fontSize="sm" color="gray.500">
                            ID: {customer.campaign_customer_id}
                          </Text>
                        </VStack>
                      </Td>
                      <Td borderColor={borderColor}>
                        <VStack align="start" spacing={2}>
                          <HStack>
                            <PhoneIcon color="blue.500" />
                            <Text>{customer.customer.phone_number}</Text>
                          </HStack>
                          {customer.customer.email && (
                            <HStack>
                              <EmailIcon color="green.500" />
                              <Text>{customer.customer.email}</Text>
                            </HStack>
                          )}
                        </VStack>
                      </Td>
                      <Td borderColor={borderColor}>
                        <Badge
                          colorScheme={getStatusColor(customer.status)}
                          px={3}
                          py={1}
                          borderRadius="full"
                        >
                          {customer.status}
                        </Badge>
                      </Td>
                      <Td borderColor={borderColor}>
                        <Text>{customer.attempt_count}</Text>
                      </Td>
                      <Td borderColor={borderColor}>
                        {customer.next_call_datetime ? (
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="medium">
                              {formatDate(customer.next_call_datetime)}
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                              Scheduled
                            </Text>
                          </VStack>
                        ) : (
                          <Text color="gray.500">Not scheduled</Text>
                        )}
                      </Td>
                      <Td borderColor={borderColor}>
                        <Button
                          size="sm"
                          leftIcon={<ViewIcon />}
                          colorScheme="blue"
                          variant="outline"
                          onClick={() => handleViewInteractions(customer)}
                        >
                          View Interactions
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              {customers.length === 0 && (
                <Box p={8} textAlign="center">
                  <VStack spacing={3}>
                    <Text fontSize="lg" color="gray.500">
                      No customers found
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      Try adjusting your filters or add new customers to the
                      campaign
                    </Text>
                  </VStack>
                </Box>
              )}

              {customers.length > 0 && (
                <Box borderTop="1px" borderColor={borderColor}>
                  <Flex justify="space-between" align="center" px={6} py={4}>
                    <Text color="gray.600">
                      Showing {(page - 1) * limit + 1} to{" "}
                      {Math.min(page * limit, pagination.total)} of{" "}
                      {pagination.total} customers
                    </Text>

                    <HStack spacing={2}>
                      <Tooltip
                        label={
                          page <= 1
                            ? "You're on the first page"
                            : "Previous page"
                        }
                      >
                        <IconButton
                          aria-label="Previous page"
                          icon={<ChevronLeftIcon />}
                          isDisabled={page <= 1}
                          onClick={() => handlePageChange(page - 1)}
                          size="sm"
                          variant="outline"
                        />
                      </Tooltip>

                      <Text px={4} fontWeight="medium">
                        Page {page} of {pagination.pages}
                      </Text>

                      <Tooltip
                        label={
                          page >= pagination.pages
                            ? "You're on the last page"
                            : "Next page"
                        }
                      >
                        <IconButton
                          aria-label="Next page"
                          icon={<ChevronRightIcon />}
                          isDisabled={page >= pagination.pages}
                          onClick={() => handlePageChange(page + 1)}
                          size="sm"
                          variant="outline"
                        />
                      </Tooltip>
                    </HStack>
                  </Flex>
                </Box>
              )}
            </Box>
          </CardBody>
        </Card>
      </VStack>

      {selectedCustomer && (
        <InteractionDrawer
          isOpen={isOpen}
          onClose={onClose}
          campaignId={parseInt(campaignId!)}
          customer={selectedCustomer}
        />
      )}
    </Container>
  );
};

export default CampaignCustomers;

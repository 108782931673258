import React from "react";
import { HStack, Text, Switch, Input } from "@chakra-ui/react";
import { NodeData } from "../type";

interface LoopConditionSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const LoopConditionSection: React.FC<LoopConditionSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  return (
    <>
      <HStack justifyContent="space-between">
        <Text fontSize="lg" fontWeight="bold" as="p">
          Loop Condition:
        </Text>
        <Switch
          isChecked={nodeData.loopConditionObject.loopCondition}
          onChange={() =>
            setNodeData((prevState) => ({
              ...nodeData,
              loopConditionObject: {
                ...nodeData.loopConditionObject,
                loopCondition: !prevState.loopConditionObject.loopCondition,
              },
            }))
          }
        />
      </HStack>
      <Text>
        Describe the condition for when the agent should move to the next node,
        or loop on this node. If the condition is not met, the agent will stay
        on this node until the condition is met. This allows you to ensure that
        the agent has received the information required, before moving on to the
        next node.
      </Text>
      <Input
        mt={2}
        placeholder="Loop Condition Example"
        value={nodeData.loopConditionObject.loopConditionExample}
        onChange={(e) =>
          setNodeData({
            ...nodeData,
            loopConditionObject: {
              ...nodeData.loopConditionObject,
              loopConditionExample: e.target.value,
            },
          })
        }
      />
    </>
  );
};

export default LoopConditionSection;

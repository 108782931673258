import { useState, useEffect } from "react";
import { Tool } from "../type";
import { getAvailableTools } from "../../../api/agents";

export const useAvailableTools = (nodeType?: string) => {
  const [availableTools, setAvailableTools] = useState<Tool[]>([]);

  useEffect(() => {
    const fetchTools = async () => {
      if (nodeType === "ToolNode") {
        try {
          const response = await getAvailableTools();
          const toolsWithIds = response.data.map(
            (tool: Tool, index: number) => ({
              ...tool,
              id: index,
            })
          );
          setAvailableTools(toolsWithIds);
        } catch (error) {
          console.error("Error fetching tools:", error);
        }
      }
    };

    fetchTools();
  }, [nodeType]);

  return { availableTools };
};

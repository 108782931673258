import React from "react";
import { Text, Flex, Button, Box, Collapse, Textarea } from "@chakra-ui/react";
import { BsLightbulb } from "react-icons/bs";
import MarkDownEditor from "../../MarkDownEditor";
import { NodeData } from "../type";

interface PromptSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
  improvedPrompt: string;
  isImprovedPromptVisible: boolean;
  handleImprove: () => void;
  handleApply: () => void;
  handleIgnore: () => void;
}

const PromptSection: React.FC<PromptSectionProps> = ({
  nodeData,
  setNodeData,
  improvedPrompt,
  isImprovedPromptVisible,
  handleImprove,
  handleApply,
  handleIgnore,
}) => {
  return (
    <>
      <Text fontSize="lg" fontWeight="bold" as="p">
        {nodeData.staticText ? "Text" : "Prompt"}:
      </Text>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Text fontSize="xs">What the tool will start with.</Text>
        <Button
          size="xs"
          bg="white"
          color="gray.600"
          boxShadow="md"
          _hover={{ bg: "gray.100" }}
          onClick={handleImprove}
          leftIcon={<BsLightbulb size="0.8em" />}
          iconSpacing={1}
        >
          Improve
        </Button>
      </Flex>
      <Box position="relative">
        <MarkDownEditor
          value={nodeData.prompt}
          setValue={(value) => {
            setNodeData({ ...nodeData, prompt: value });
          }}
          height="200px"
        />
      </Box>
      <Collapse in={isImprovedPromptVisible} animateOpacity>
        <Box mt={4} position="relative">
          <Textarea
            value={improvedPrompt}
            onChange={(e) =>
              setNodeData({ ...nodeData, prompt: e.target.value })
            }
            placeholder="Improved prompt will appear here"
            size="md"
            resize="vertical"
            pr="110px"
            minHeight="150px"
          />
          <Flex position="absolute" bottom="8px" right="8px" zIndex={2}>
            <Button
              size="xs"
              variant="outline"
              mr={2}
              onClick={handleIgnore}
              bg="white"
            >
              Ignore
            </Button>
            <Button size="xs" colorScheme="blue" onClick={handleApply}>
              Apply
            </Button>
          </Flex>
        </Box>
      </Collapse>
    </>
  );
};

export default PromptSection;

import { apiWithAuth as api } from "../apiService";

export interface CreateUpdateWorkflow {
  configuration: Record<string, any>;
  updatedBy: string;
}

export interface WorkflowConfig {
  organisationId: string;
  workflowId: string;
  workflowName: string;
  configuration: Record<string, any>;
  createdAt?: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy: string;
}

export const getOrganisationWorkflows = async (organisationId: string) => {
  const response = await api.get(`/organisations/${organisationId}/workflows`);
  return response.data;
};

export const getOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string
) => {
  const response = await api.get(
    `/organisations/${organisationId}/workflows/${workflowId}`
  );
  return response.data;
};

export const createOrganisationWorkflow = async (
  organisationId: string,
  data: CreateUpdateWorkflow
) => {
  const response = await api.post(
    `/organisations/${organisationId}/workflows`,
    data
  );
  return response.data;
};

export const updateOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string,
  data: CreateUpdateWorkflow
) => {
  const response = await api.patch(
    `/organisations/${organisationId}/workflows/${workflowId}`,
    data
  );
  return response.data;
};

export const deleteOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string
) => {
  const response = await api.delete(
    `/organisations/${organisationId}/workflows/${workflowId}`
  );
  return response.data;
};

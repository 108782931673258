import React from "react";
import {
  FormControl,
  Input,
  Select,
  Switch,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Textarea,
  NumberInput,
  NumberInputField,
  Box,
  HStack,
  IconButton,
  useColorModeValue,
  Tooltip,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { InfoIcon, ArrowUpDownIcon } from "@chakra-ui/icons";

interface VoiceFormFieldProps {
  name: string;
  label: string;
  tooltip: string;
  type: "input" | "select" | "switch" | "slider" | "textarea" | "number";
  value: any;
  onChange: (value: any) => void;
  options?: any;
  isArray?: boolean;
}

export const InfoTooltip = ({
  toolTipLabel,
  tooltip,
}: {
  toolTipLabel: string;
  tooltip: string;
}) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <FormLabel>
      <HStack spacing={1} alignItems="center">
        <Text>{toolTipLabel}</Text>
        <Tooltip
          label={tooltip}
          hasArrow
          placement="top"
          bg={bgColor}
          color={textColor}
          px={3}
          py={2}
          borderRadius="md"
          fontWeight="medium"
          fontSize="sm"
        >
          <InfoIcon boxSize={3} color="gray.500" />
        </Tooltip>
      </HStack>
    </FormLabel>
  );
};

export const VoiceFormField: React.FC<VoiceFormFieldProps> = ({
  name,
  label,
  tooltip,
  type,
  value,
  onChange,
  options,
  isArray,
}) => {
  const renderField = () => {
    switch (type) {
      case "input":
        return (
          <Input
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        );
      case "select":
        return (
          <HStack>
            <Select
              name={name}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              {options.options.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {options.playPreview && (
              <IconButton
                aria-label="Play preview"
                icon={<ArrowUpDownIcon />}
                onClick={() =>
                  options.playPreview(
                    options.options.find((o: any) => o.value === value)
                      ?.preview_audio_url
                  )
                }
              />
            )}
          </HStack>
        );
      case "switch":
        return (
          <Switch
            name={name}
            isChecked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
        );
      case "slider":
        return (
          <Box width="50%" p={2}>
            <Slider
              min={options.min}
              max={options.max}
              step={options.step}
              value={value}
              onChange={(val) => onChange(val)}
            >
              <SliderMark value={options.min} mt="1" ml="-2.5" fontSize="sm">
                {options.minLabel || options.min}
              </SliderMark>
              <SliderMark value={options.max} mt="1" ml="-2.5" fontSize="sm">
                {options.maxLabel || options.max}
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        );
      case "textarea":
        return (
          <Textarea
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        );
      case "number":
        return (
          <NumberInput
            value={value}
            onChange={(valueString) => onChange(parseFloat(valueString))}
          >
            <NumberInputField name={name} />
          </NumberInput>
        );
      default:
        return null;
    }
  };

  return (
    <FormControl>
      <InfoTooltip toolTipLabel={label} tooltip={tooltip} />
      {renderField()}
    </FormControl>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  VStack,
  Divider,
  Button,
  Tooltip,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { FaRegClone } from "react-icons/fa";
import {
  createOrganisationWorkflow,
  getOrganisationWorkflow,
  WorkflowConfig,
} from "../../api/workflows";
import {
  getOrganisationWorkflows,
  deleteOrganisationWorkflow,
} from "../../api/workflows";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

const Workflows: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [workflows, setWorkflows] = useState<WorkflowConfig[]>([]);

  const filteredWorkflows = workflows.filter((workflow) =>
    (workflow.workflowName?.toLowerCase() ?? "").includes(
      searchQuery.toLowerCase()
    )
  );

  useEffect(() => {
    getWorkflows();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getWorkflows = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getOrganisationWorkflows("1");
      setWorkflows(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteWorkflow = useCallback(async (workflowId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteOrganisationWorkflow("1", workflowId);
        setWorkflows((prevWorkflows) =>
          prevWorkflows.filter((workflow) => workflow.workflowId !== workflowId)
        );
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreateWorkflow = useCallback(() => {
    navigate("/dashboard/workflow/new");
  }, [navigate]);

  const handleEditWorkflow = useCallback((workflowId: string) => {
    navigate(`/dashboard/workflow/${workflowId}`);
  }, []);

  const cloneWorkflow = useCallback(
    async (workflowId: string) => {
      setIsLoading(true);
      try {
        const workflowToClone = workflows.find(
          (workflow) => workflow.workflowId === workflowId
        );
        if (workflowToClone == null) {
          return;
        }
        const currentWorkflowConfiguration = await getOrganisationWorkflow(
          "1",
          workflowToClone.workflowId
        );
        const randomId = nanoid();
        const workflow: WorkflowConfig = await createOrganisationWorkflow("1", {
          ...currentWorkflowConfiguration,
          workflowName: `${
            workflowToClone.workflowName
          } (Clone-${randomId.slice(-3)})`,
        });
        navigate(`/dashboard/workflow/${workflow.workflowId}`);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [workflows]
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <HStack mb={5}>
          <Text as="h1" fontSize="2xl">
            Workflows
          </Text>
        </HStack>

        <HStack mb={4}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="teal"
            aria-label="Create Workflow"
            onClick={handleCreateWorkflow}
          >
            + New Workflow
          </Button>
        </HStack>

        <InputGroup mb={4}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search workflows..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>

        {isLoading ? (
          <Spinner mt={5} />
        ) : (
          <Box>
            <HStack spacing={4} wrap="wrap">
              {filteredWorkflows.map((workflow) => (
                <Box
                  key={workflow.workflowId}
                  bgColor="gray.100"
                  borderRadius={5}
                  p={2}
                  my={2}
                  height={180}
                  width={{ base: "100%", sm: "48%", md: "30%" }}
                >
                  <VStack
                    alignItems={"start"}
                    justifyContent={"space-between"}
                    height={"100%"}
                  >
                    <VStack
                      alignItems={"start"}
                      width={"90%"}
                      overflow={"hidden"}
                    >
                      <Text fontSize={"lg"}>{workflow.workflowName}</Text>
                    </VStack>

                    <Box width={"100%"}>
                      <Divider borderColor={"black"} />
                      <HStack justifyContent="space-between" mt={2}>
                        <HStack>
                          <Text>{workflow.updatedBy}</Text>
                        </HStack>
                        <HStack>
                          <Tooltip label="Edit Workflow">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Workflow"
                              icon={<EditIcon />}
                              onClick={() =>
                                handleEditWorkflow(workflow.workflowId)
                              }
                            />
                          </Tooltip>
                          <Tooltip label="Clone Workflow">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Clone Workflow"
                              icon={<FaRegClone />}
                              onClick={() => cloneWorkflow(workflow.workflowId)}
                            />
                          </Tooltip>
                          <Tooltip label="Delete Workflow">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="red"
                              aria-label="Delete Workflow"
                              icon={<DeleteIcon />}
                              onClick={() =>
                                deleteWorkflow(workflow.workflowId)
                              }
                            />
                          </Tooltip>
                        </HStack>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </HStack>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Workflows;

import React from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormErrorMessage,
  HStack,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

interface RetriesConfigProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

const RetriesConfig: React.FC<RetriesConfigProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  return (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.maxCallDuration}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Maximum Call Duration (seconds)</FormLabel>
          <Tooltip
            label="Maximum duration allowed for each call in the campaign"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <NumberInput
          min={1}
          value={formData.maxCallDuration}
          onChange={(value) =>
            setFormData({ ...formData, maxCallDuration: parseInt(value) })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{errors.maxCallDuration}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.retryTime}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Retry Time (seconds)</FormLabel>
          <Tooltip
            label="Time to wait before retrying a failed call attempt"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <NumberInput
          min={1}
          value={formData.retryTime}
          onChange={(value) =>
            setFormData({ ...formData, retryTime: parseInt(value) })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{errors.retryTime}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default RetriesConfig;

import { useState } from "react";
import {
  campaignFormSchema,
  aboutCampaignSchema,
  schedulingDetailsSchema,
  retriesConfigSchema,
  configSelectionSchema,
  customerDataSchema,
  CampaignFormData,
} from "../schemas/campaignSchemas";

const getDefaultStartDateTime = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(9, 0, 0, 0);
  return tomorrow.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
};

const getDefaultEndDateTime = () => {
  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  nextWeek.setHours(17, 0, 0, 0);
  return nextWeek.toISOString().slice(0, 16);
};

const initialFormData: CampaignFormData = {
  name: "",
  description: "",
  startType: "immediate",
  startDateTime: getDefaultStartDateTime(),
  endDateTime: getDefaultEndDateTime(),
  maxAttempts: 3,
  callWindows: [{ start: "12:00", end: "23:59" }],
  schedulingType: "interval",
  interval: 30,
  scheduledDateTimes: [],
  maxCallDuration: 60,
  retryTime: 300,
  pathway: "",
  voiceConfig: "",
  communicationChannel: "CALL",
  dataSource: "file",
  customerFile: null,
  uploadedFileUrl: null,
  selectedCustomers: [],
  selectedNumbers: [],
  successCriteria: "",
};

export const useCampaignForm = () => {
  const [formData, setFormData] = useState<CampaignFormData>(initialFormData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateStep = (step: number) => {
    try {
      switch (step) {
        case 0:
          aboutCampaignSchema.parse(formData);
          break;
        case 1:
          schedulingDetailsSchema.parse(formData);
          break;
        case 2:
          retriesConfigSchema.parse(formData);
          break;
        case 3:
          configSelectionSchema.parse(formData);
          break;
        case 4:
          customerDataSchema.parse(formData);
          break;
        default:
          return false;
      }
      setErrors({});
      return true;
    } catch (error: any) {
      const formattedErrors: Record<string, string> = {};
      error.errors.forEach((err: any) => {
        const path = err.path.join(".");
        if (path) {
          formattedErrors[path] = err.message;
        }
      });
      setErrors(formattedErrors);
      return false;
    }
  };

  const validateForm = () => {
    try {
      campaignFormSchema.parse(formData);
      setErrors({});
      return true;
    } catch (error: any) {
      const formattedErrors: Record<string, string> = {};
      error.errors.forEach((err: any) => {
        const path = err.path.join(".");
        if (path) {
          formattedErrors[path] = err.message;
        }
      });
      setErrors(formattedErrors);
      return false;
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  const setFieldError = (field: string, message: string) => {
    setErrors((prev) => ({ ...prev, [field]: message }));
  };

  return {
    formData,
    setFormData,
    errors,
    validateStep,
    validateForm,
    clearErrors,
    setFieldError,
    handleSubmit: () => (validateForm() ? formData : null),
  };
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Input,
  Button,
  useToast,
  FormControl,
  IconButton,
} from "@chakra-ui/react";
import {
  AddIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { AgentCreateParams, AgentUpdateParams } from "./type";
import {
  createVoiceConfiguration,
  getVoiceConfiguration,
  getVoices,
  updateVoiceConfiguration,
} from "../../api/voice";
import { COMMN_WS_URL } from "../../constants/app_constants";
import { VoiceFormField, InfoTooltip } from './VoiceFormField';

const VoiceForm: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [voiceConfig, setVoiceConfig] = useState<
    AgentCreateParams | AgentUpdateParams
  >({
    llm_websocket_url: `${COMMN_WS_URL}/stream/retell-websocket`,
    voice_id: "11labs-Myra",
    agent_name: "",
    ambient_sound: null,
    ambient_sound_volume: 0.8,
    backchannel_frequency: 0.7,
    backchannel_words: [],
    boosted_keywords: [],
    enable_backchannel: false,
    end_call_after_silence_ms: 30000,
    fallback_voice_ids: [],
    interruption_sensitivity: 0.8,
    language: "en-US",
    normalize_for_speech: true,
    opt_out_sensitive_data_storage: true,
    reminder_max_count: 2,
    reminder_trigger_ms: 15000,
    responsiveness: 0.9,
    voice_speed: 1.1,
    voice_temperature: 0.8,
    pronunciation_dictionary: [],
  });

  const toast = useToast();
  const navigate = useNavigate();
  const [voices, setVoices] = useState<any[]>([]);

  const loadVoices = async () => {
    await getVoices().then((res) => {
      setVoices(res);
    });
  };

  useEffect(() => {
    loadVoices();
  }, []);

  useEffect(() => {
    console.log(voiceConfig);
  }, [voiceConfig]);

  useEffect(() => {
    if (agentId) {
      const fetchVoiceConfig = async () => {
        try {
          const response = await getVoiceConfiguration(agentId);
          if (response) {
            setVoiceConfig((prev) => {
              const updatedConfig = { ...prev, ...response };
              if (response.llm_websocket_url === "") {
                updatedConfig.llm_websocket_url = prev.llm_websocket_url;
              }
              return updatedConfig;
            });
            console.log(response);
          } else {
            throw new Error("Voice configuration not found");
          }
        } catch (error) {
          toast({
            title: "Error",
            description: "Failed to load voice configuration.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      };
      fetchVoiceConfig();
    }
  }, [agentId, toast]);

  const languageOptions = [
    { value: "en-US", label: "English (US)" },
    { value: "en-IN", label: "English (India)" },
    { value: "en-GB", label: "English (UK)" },
    { value: "de-DE", label: "German" },
    { value: "es-ES", label: "Spanish (Spain)" },
    { value: "es-419", label: "Spanish (Latin America)" },
    { value: "hi-IN", label: "Hindi" },
    { value: "ja-JP", label: "Japanese" },
    { value: "pt-PT", label: "Portuguese (Portugal)" },
    { value: "pt-BR", label: "Portuguese (Brazil)" },
    { value: "fr-FR", label: "French" },
  ];

  const ambientSoundOptions = [
    { value: undefined, label: "None" },
    { value: "coffee-shop", label: "Coffee Shop" },
    { value: "convention-hall", label: "Convention Hall" },
    { value: "summer-outdoor", label: "Summer Outdoor" },
    { value: "mountain-outdoor", label: "Mountain Outdoor" },
    { value: "static-noise", label: "Static Noise" },
    { value: "call-center", label: "Call Center" },
  ];

  const renderFormField = (
    name: keyof AgentCreateParams | AgentUpdateParams,
    label: string,
    tooltip: string,
    type: 'input' | 'select' | 'switch' | 'slider' | 'textarea' | 'number',
    options?: any,
    isArray?: boolean
  ) => (
    <VoiceFormField
      name={name as keyof typeof voiceConfig}
      label={label}
      tooltip={tooltip}
      type={type}
      value={name === 'fallback_voice_ids' ? voiceConfig.fallback_voice_ids?.[0] || '' : voiceConfig[name as keyof typeof voiceConfig]}
      onChange={(value: any) => {
        if (name === 'fallback_voice_ids') {
          setVoiceConfig((prev) => ({ ...prev, fallback_voice_ids: [value] }));
        } else if (isArray && type === 'textarea') {
          const arrayValue = value.split(',').map((item: string) => item.trim()).filter((item: string) => item !== '');
          setVoiceConfig((prev) => ({ ...prev, [name as keyof typeof voiceConfig]: arrayValue }));
        } else {
          setVoiceConfig((prev) => ({ ...prev, [name as keyof typeof voiceConfig]: value }));
        }
      }}
      options={options}
      isArray={isArray}
    />
  );

  const handlePronunciationChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newPronunciationDictionary = [
      ...(voiceConfig.pronunciation_dictionary || []),
    ];
    newPronunciationDictionary[index] = {
      ...newPronunciationDictionary[index],
      [field]: value,
    };
    setVoiceConfig((prev) => ({
      ...prev,
      pronunciation_dictionary: newPronunciationDictionary,
    }));
  };

  const addPronunciation = () => {
    setVoiceConfig((prev) => ({
      ...prev,
      pronunciation_dictionary: [
        ...(prev.pronunciation_dictionary || []),
        { alphabet: "ipa", phoneme: "", word: "" },
      ],
    }));
  };

  const removePronunciation = (index: number) => {
    const newPronunciationDictionary = (
      voiceConfig.pronunciation_dictionary || []
    ).filter((_, i) => i !== index);
    setVoiceConfig((prev) => ({
      ...prev,
      pronunciation_dictionary: newPronunciationDictionary,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (agentId) {
        await updateVoiceConfiguration(
          agentId,
          voiceConfig as AgentUpdateParams
        );
      } else {
        await createVoiceConfiguration(voiceConfig as AgentCreateParams);
      }
      toast({
        title: "Success",
        description: `Voice configuration ${
          agentId ? "updated" : "created"
        } successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/dashboard/voices");
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const playPreviewAudio = (previewUrl: string) => {
    if (previewUrl) {
      const audio = new Audio(previewUrl);
      audio.play();
    }
  };

  return (
    <Box height={"100%"} p={4}>
      <VStack spacing={4} align="stretch">
        <Text as="h1" fontSize="2xl">
          {agentId ? "Edit Voice Configuration" : "Create Voice Configuration"}
        </Text>
        
        {renderFormField('agent_name', 'Agent Name', 'Enter a unique name for this voice configuration', 'input')}
        {renderFormField('voice_id', 'Voice', 'Select the voice to use', 'select', { 
          options: voices.map(v => ({ 
            value: v.voice_id, 
            label: `${v.voice_name} (${v.provider}, ${v.accent}, ${v.gender})`,
            preview_audio_url: v.preview_audio_url 
          })), 
          playPreview: playPreviewAudio 
        })}
        {renderFormField('language', 'Language', 'Select the primary language for this voice configuration', 'select', { options: languageOptions })}
        {renderFormField('enable_backchannel', 'Enable Backchannel', 'Toggle to enable or disable backchannel responses', 'switch')}
        
        {voiceConfig.enable_backchannel && (
          <>
            {renderFormField('backchannel_frequency', 'Backchannel Frequency', 'Adjust the frequency of backchannel responses', 'slider', { min: 0, max: 1, step: 0.01, minLabel: 'Low', maxLabel: 'High' })}
            {renderFormField('backchannel_words', 'Backchannel Words', 'Enter comma-separated words or phrases for backchannel responses', 'textarea', undefined, true)}
          </>
        )}
        {renderFormField('normalize_for_speech', 'Normalize for Speech', 'Toggle to normalize or not normalize speech', 'switch')}
        {renderFormField('opt_out_sensitive_data_storage', 'Opt Out Sensitive Data Storage', 'Toggle to opt out or not opt out sensitive data storage', 'switch')}
        {renderFormField('responsiveness', 'Responsiveness', 'Adjust the responsiveness of the voice', 'slider', { min: 0, max: 1, step: 0.01, minLabel: 'Slow', maxLabel: 'Fast' })}
        {renderFormField('voice_speed', 'Voice Speed', 'Adjust the speed of the voice', 'slider', { min: 0.5, max: 2, step: 0.01, minLabel: 'Slow', maxLabel: 'Fast' })}
        {renderFormField('voice_temperature', 'Voice Temperature', 'Adjust the temperature of the voice', 'slider', { min: 0.5, max: 2, step: 0.01, minLabel: 'Calm', maxLabel: 'Emotional' })}
        <FormControl>
          <InfoTooltip
            toolTipLabel="Pronunciation Dictionary"
            tooltip="Add custom pronunciations for specific words"
          />
          {(voiceConfig.pronunciation_dictionary || []).map((entry, index) => (
            <HStack key={index} spacing={2} mb={2}>
              <Input
                placeholder="Alphabet"
                value={entry.alphabet}
                onChange={(e) =>
                  handlePronunciationChange(index, "alphabet", e.target.value)
                }
              />
              <Input
                placeholder="Phoneme"
                value={entry.phoneme}
                onChange={(e) =>
                  handlePronunciationChange(index, "phoneme", e.target.value)
                }
              />
              <Input
                placeholder="Word"
                value={entry.word}
                onChange={(e) =>
                  handlePronunciationChange(index, "word", e.target.value)
                }
              />
              <IconButton
                aria-label="Delete pronunciation"
                icon={<DeleteIcon />}
                onClick={() => removePronunciation(index)}
              />
            </HStack>
          ))}
          <Button leftIcon={<AddIcon />} onClick={addPronunciation}>
            Add Pronunciation
          </Button>
        </FormControl>
        {renderFormField('ambient_sound', 'Ambient Sound', 'Select an ambient sound to play during the call', 'select', { options: ambientSoundOptions })}
        {voiceConfig.ambient_sound && renderFormField('ambient_sound_volume', 'Ambient Sound Volume', 'Adjust the volume of the ambient sound', 'number')}
        {renderFormField('boosted_keywords', 'Boosted Keywords', 'Enter comma-separated keywords to boost their emphasis in the speech', 'textarea', undefined, true)}
        {renderFormField('end_call_after_silence_ms', 'End Call After Silence (ms)', 'Enter the duration of silence after which the call should end', 'number')}
        {renderFormField('fallback_voice_ids', 'Fallback Voice', 'Select a fallback voice to use if the primary voice is not available', 'select', { 
          options: voices.map(v => ({ 
            value: v.voice_id, 
            label: `${v.voice_name} (${v.provider}, ${v.accent}, ${v.gender})`,
            preview_audio_url: v.preview_audio_url 
          })), 
          playPreview: playPreviewAudio 
        })}
        {renderFormField('interruption_sensitivity', 'Interruption Sensitivity', 'Adjust the sensitivity to interruptions during the call', 'number')}
        {renderFormField('reminder_max_count', 'Reminder Max Count', 'Enter the maximum number of reminders to be sent during the call', 'number')}
        {renderFormField('reminder_trigger_ms', 'Reminder Trigger (ms)', 'Enter the duration of silence after which a reminder should be sent', 'number')}
        <HStack spacing={4}>
          <Button colorScheme="teal" onClick={handleSubmit}>
            {agentId ? "Update" : "Create"}
          </Button>
          <Button
            variant="outline"
            onClick={() => navigate("/dashboard/voices")}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default VoiceForm;
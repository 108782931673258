import React, { useCallback, useState, useEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  HStack,
  Input,
  useToast,
  VStack,
  Flex,
} from "@chakra-ui/react";
import {
  ReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  BackgroundVariant,
  Panel,
  Controls,
  Node,
  Edge,
  OnConnect,
  OnNodesChange,
  OnEdgesChange,
  NodeTypes,
  EdgeTypes,
} from "@xyflow/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import "@xyflow/react/dist/style.css";

import ProcessNode from "../../components/Nodes/ProcessNode";
import ProcessEdge from "../../components/Edges/ProcessEdge";
import {
  getOrganisationAgent,
  updateOrganisationAgent,
  createOrganisationAgent,
} from "../../api/agents";
import { AgentConfig } from "../../components/Agents/type";
import useRetellWebCall from "../../hooks/useRetellWebCall";
import { GraphType } from "../../api/voice";

const AgentBuilder: React.FC = () => {
  const { agentId } = useParams<{ agentId: string | undefined }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [callNumber, setCallNumber] = useState("");
  const [agentName, setAgentName] = useState("");
  const [isTestButtonDisabled, setIsTestButtonDisabled] = useState(false);

  const nodeTypes: NodeTypes = useMemo(() => ({ ProcessNode }), []);
  const edgeTypes: EdgeTypes = useMemo(() => ({ ProcessEdge }), []);
  const { initiateCall, stopCall, isCallActive, isLoading: isRetellCallLoading } = useRetellWebCall();

  useEffect(() => {
    if (agentId) {
      getAgentState();
    }
  }, [agentId]);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
          ? error.response.data
          : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getAgentState = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AgentConfig = await getOrganisationAgent("1", agentId!);
      setNodes(response.configuration.nodes);
      setEdges(response.configuration.edges);
      setCallNumber(response.callNumber);
      if (response.agentName.trim() === "") {
        setAgentName(response.agentId);
      } else {
        setAgentName(response.agentName);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [agentId]);

  const handleTestAgent = async () => {
    if (isTestButtonDisabled) return;

    if (isCallActive) {
      stopCall();
    } else {
      setIsTestButtonDisabled(true);
      try {
        await initiateCall({ nodes, edges }, GraphType.MNA);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to initiate call. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsTestButtonDisabled(false);
      }
    }
  };

  const saveAgentState = useCallback(async () => {
    if (nodes.length === 0) {
      return;
    }

    const filteredCallNumber = callNumber.length < 10 ? "NA" : callNumber;
    const data = {
      configuration: { nodes, edges },
      updatedBy: "test@test.com",
      callNumber: filteredCallNumber,
      agentName: agentName,
      type: "mna",
    };

    try {
      setIsLoading(true);
      if (agentId) {
        await updateOrganisationAgent("1", agentId, data);
      } else {
        const agent: AgentConfig = await createOrganisationAgent("1", data);
        navigate(`/dashboard/agent/mna/${agent.agentId}`, { replace: true });
      }
      toast({
        title: "Success",
        description: "Agent state saved!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [agentId, agentName, nodes, edges, callNumber]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect: OnConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "ProcessEdge",
            data: { edgeCondition: "", edgeDescription: "" },
          },
          eds
        )
      ),
    []
  );

  const handleAddNode = useCallback(() => {
    const nodeId = nanoid();
    let isPrimaryNode = false;
    if (nodes.length === 0) {
      isPrimaryNode = true;
    }

    const newNode: Node = {
      id: nodeId,
      data: {
        label: `New Node`,
        prompt: "",
        tools: [],
        isPrimaryNode,
        sensitiveTools: [],
        allowWebSearch: false,
      },
      type: "ProcessNode",
      position: {
        x: Math.random() * 500,
        y: Math.random() * 300,
      },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [nodes]);

  const handleCallNumberInput = useCallback((inputValue: string) => {
    const parsedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    if (parsedValue.length <= 12) {
      setCallNumber(parsedValue || "");
    }
  }, []);

  return (
    <Flex height="100%" flexDirection="column">
      <Box flex={1} position="relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{ hideAttribution: true }}
        >
          <Background color="#ccc" variant={BackgroundVariant.Cross} />
          <Panel position="top-left">
            {/* <IconButton
              colorScheme="blue"
              aria-label="Go Back"
              icon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            /> */}
            <Button
              ml={2}
              colorScheme="blue"
              onClick={handleAddNode}
              isDisabled={isLoading}
              size={"sm"}
            >
              Add Node
            </Button>
          </Panel>
          <Panel position="top-right">
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <HStack>
                <VStack>
                  <Input
                    bgColor="white"
                    variant="outline"
                    placeholder="Give your agent a name"
                    value={agentName}
                    onChange={(event) => setAgentName(event.target.value)}
                    isDisabled={isLoading}
                    size={"sm"}
                  />
                  <Input
                    bgColor="white"
                    variant="outline"
                    placeholder="Calling Number"
                    value={callNumber}
                    onChange={(event) =>
                      handleCallNumberInput(event.target.value)
                    }
                    isDisabled={isLoading}
                    size={"sm"}
                  />
                </VStack>
                <VStack>
                  <Button
                    colorScheme="blue"
                    onClick={saveAgentState}
                    isLoading={isLoading}
                    size={"sm"}
                  >
                    Save
                  </Button>
                  <Button
                    colorScheme={isCallActive ? "red" : "green"}
                    onClick={handleTestAgent}
                    isLoading={isRetellCallLoading}
                    size={"sm"}
                  >
                    {isCallActive ? "Stop Call" : "Call"}
                  </Button>
                </VStack>
              </HStack>
            </Box>
          </Panel>
          <Controls />
        </ReactFlow>
      </Box>
    </Flex>
  );
};

export default AgentBuilder;

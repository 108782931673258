import React, { useState } from "react";
import { Flex, Input, Button, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { AIType, sendNewChatMessage } from "../../api/chat";

interface ChatNewProps {
  aiPower: string;
  llmType: string;
  context: string;
}

const ChatNew: React.FC<ChatNewProps> = ({ aiPower, llmType, context }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      try {
        setIsLoading(true);
        const chatRequest = {
          history: [],
          query: inputMessage,
          context: context,
          aiType: llmType === "openai" ? AIType.GPT : AIType.CLAUDE,
          aiPower: aiPower,
        };

        const response = await sendNewChatMessage(chatRequest);
        navigate(`/dashboard/chats/${response.conversationId}`);
      } catch (error) {
        console.error("Error sending message:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex height="100%" flexDirection="column">
      <Flex
        p={4}
        flexDirection={"column"}
        gap={4}
        bg="white"
        alignItems="center"
        margin={"auto"}
        width={"80%"}
      >
        <Text fontSize={"2xl"} fontWeight={"bold"}>
          What can I help you with today?
        </Text>
        <Input
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type a message..."
          mr={2}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <Button
          onClick={handleSendMessage}
          colorScheme="blue"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Ask
        </Button>
      </Flex>
    </Flex>
  );
};

export default ChatNew;

import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Button,
  IconButton,
  Divider,
  Spinner,
  Center,
  useToast,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { listCampaigns, Campaign } from "../../api/campaigns";
import { TbListDetails } from "react-icons/tb";

const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);
      const fetchedCampaigns = await listCampaigns();
      setCampaigns(fetchedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to fetch campaigns",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCampaign = () => {
    navigate("/dashboard/campaigns/create");
  };

  const handleViewCampaign = (campaignId: number) => {
    navigate(`/dashboard/campaigns/${campaignId}`);
  };

  const formatDate = (timestamp: string) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box height={"100%"}>
      <HStack mb={5}>
        <Box mb={8} px={0}>
          <Heading size="lg">Campaigns</Heading>
        </Box>
      </HStack>
      <HStack>
        <Button
          size="sm"
          variant="outline"
          colorScheme="teal"
          aria-label="Create Campaign"
          onClick={handleCreateCampaign}
        >
          + New Campaign
        </Button>
      </HStack>

      {isLoading ? (
        <Spinner mt={5} />
      ) : (
        <Box>
          <HStack spacing={4} wrap="wrap">
            {campaigns.length === 0 ? (
              <Box width="100%" textAlign="center" py={8}>
                <Text color="gray.500" fontSize="lg">
                  No Campaigns to show
                </Text>
              </Box>
            ) : (
              campaigns.map((campaign) => (
                <Box
                  key={campaign.campaign_id}
                  bgColor="gray.100"
                  borderRadius={5}
                  p={2}
                  my={2}
                  height={180}
                  width={{ base: "100%", sm: "48%", md: "30%" }}
                >
                  <VStack
                    alignItems={"start"}
                    justifyContent={"space-between"}
                    height={"100%"}
                  >
                    <VStack
                      alignItems={"start"}
                      width={"90%"}
                      overflow={"hidden"}
                    >
                      <Text fontSize={"lg"}>{campaign.campaign_name}</Text>
                      <Text fontSize={"sm"} noOfLines={2}>
                        {campaign.campaign_description}
                      </Text>
                      <Text fontSize={"sm"}>Status: {campaign.status}</Text>
                      <Text fontSize={"sm"}>
                        Created: {formatDate(campaign.created_at)}
                      </Text>
                    </VStack>

                    <Box width={"100%"}>
                      <Divider borderColor={"black"} />
                      <HStack justifyContent="space-between" mt={2}>
                        <HStack>
                          <Text>{campaign.user.email}</Text>
                        </HStack>
                        <HStack>
                          <Tooltip label="View Campaign">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="teal"
                              aria-label="View Campaign"
                              icon={<TbListDetails />}
                              onClick={() =>
                                handleViewCampaign(campaign.campaign_id)
                              }
                            />
                          </Tooltip>
                        </HStack>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
              ))
            )}
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default Campaigns;

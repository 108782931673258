import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import AboutCampaign from "./steps/AboutCampaign";
import SchedulingDetails from "./steps/SchedulingDetails";
import RetriesConfig from "./steps/RetriesConfig";
import ConfigSelection from "./steps/ConfigSelection";
import CustomerData from "./steps/CustomerData";
import FormErrors from "./components/FormErrors";

import { useCampaignForm } from "./hooks/useCampaignForm";
import { prepareCampaignData } from "./utils/campaignDataPreparation";
import { createCampaign } from "../../api/campaigns";
import { useNavigate } from "react-router-dom";

const steps = [
  { title: "About", description: "Campaign details" },
  { title: "Schedule", description: "Scheduling details" },
  { title: "Retries", description: "Retry configuration" },
  { title: "Config", description: "Select configurations" },
  { title: "Customers", description: "Customer data" },
];

const CreateCampaign: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const { formData, setFormData, errors, validateStep, handleSubmit } =
    useCampaignForm();

  const handleNext = () => {
    console.log(formData);
    const isValid = validateStep(activeStep);
    if (isValid) {
      setActiveStep((prev) => prev + 1);
    } else {
      toast({
        title: "Validation Error",
        description: "Please fix the errors before proceeding",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleCreateCampaign = async () => {
    const validatedData = handleSubmit();
    if (validatedData) {
      setIsCreating(true);
      try {
        // Prepare campaign data
        const campaignData = prepareCampaignData(validatedData);

        console.log(campaignData);

        // Create campaign
        await createCampaign(campaignData);

        toast({
          title: "Campaign Created",
          description: "Your campaign has been created successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Navigate to campaigns list
        navigate("/dashboard/campaigns");
      } catch (error) {
        console.error("Error creating campaign:", error);
        toast({
          title: "Error",
          description:
            error instanceof Error
              ? error.message
              : "Failed to create campaign",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsCreating(false);
      }
    }
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <AboutCampaign
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 1:
        return (
          <SchedulingDetails
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 2:
        return (
          <RetriesConfig
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 3:
        return (
          <ConfigSelection
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      case 4:
        return (
          <CustomerData
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  const isCreateDisabled = () => {
    console.log(formData);
    if (isCreating) return true;

    // If we're on the last step and using file upload
    if (activeStep === steps.length - 1 && formData.dataSource === "file") {
      // Check if file is uploaded (uploadedFileUrl should be present)
      return !formData.uploadedFileUrl;
    }

    return false;
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={8}>Create Campaign</Heading>

      <Stepper index={activeStep} mb={8}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box flexShrink={0}>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box bg="white" p={6} borderRadius="lg" shadow="sm">
        <FormErrors errors={errors} />
        {renderStep()}

        <Flex mt={8} justify="space-between">
          <Button
            onClick={handleBack}
            isDisabled={activeStep === 0 || isCreating}
            variant="outline"
          >
            Back
          </Button>

          {activeStep === steps.length - 1 ? (
            <Button
              colorScheme="blue"
              onClick={handleCreateCampaign}
              isLoading={isCreating}
              loadingText="Creating Campaign..."
              isDisabled={isCreateDisabled()}
            >
              Create Campaign
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              onClick={handleNext}
              isDisabled={isCreating}
            >
              Next
            </Button>
          )}
        </Flex>
      </Box>
    </Container>
  );
};

export default CreateCampaign;

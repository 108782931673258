import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import LandingPage from "./pages/LandingPageV2";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AgentBuilder from "./pages/AgentBuilder";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import "./fonts/the-youngest-serif-display.ttf";
import { ChatPage, ChatHistory } from "./pages/Chat";
import CPWBuilder from "./pages/CPWBuilder";
import Agents from "./components/Agents";
import Voices from "./pages/Voices";
import Settings from "./pages/Settings/Settings";
import VoiceForm from "./pages/Voices/VoiceForm";
import KnowledgeBase from "./pages/KnowledgeBase";
import Campaigns from "./pages/Campaigns";
// import CampaignCreator from "./pages/Campaigns/CampaignCreator";
import CreateKnowledgeBase from "./pages/KnowledgeBase/CreateKnowledgeBase";
import { GoogleOAuthProvider } from "@react-oauth/google";
import EditKnowledgeBase from "./pages/KnowledgeBase/EditKnowledgeBase";
import CreateCampaign from "./pages/Campaigns/CreateCampaign";
import CampaignDetails from "./pages/Campaigns/CampaignDetails";
import CampaignCustomers from "./pages/Campaigns/CampaignCustomers";

import Contacts from "./pages/Contacts/Contacts";
import Analytics from "./pages/Analytics";
import Workflows from "./pages/Workflows";
import WorkflowBuilder from "./pages/Workflows/WorkflowBuilder";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/cpw/new",
    element: <CPWBuilder />,
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/dashboard",
    element: (
      <GoogleOAuthProvider clientId={clientId}>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </GoogleOAuthProvider>
    ),
    children: [
      {
        path: "agents",
        element: <Agents />,
      },
      {
        path: "agent/mna/new",
        element: <AgentBuilder />,
      },
      {
        path: "agent/mna/:agentId",
        element: <AgentBuilder />,
      },
      {
        path: "agent/cpw/new",
        element: <CPWBuilder />,
      },
      {
        path: "agent/cpw/:agentId",
        element: <CPWBuilder />,
      },
      {
        path: "chats",
        element: <ChatHistory />,
        children: [
          {
            path: ":conversationId",
            element: <ChatPage />,
          },
        ],
      },
      {
        path: "voices",
        element: <Voices />,
      },
      {
        path: "voice/new",
        element: <VoiceForm />,
      },
      {
        path: "voice/edit/:agentId",
        element: <VoiceForm />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "contacts",
        element: <Contacts />,
      },
      {
        path: "workflows",
        element: <Workflows />,
      },
      {
        path: "workflow/new",
        element: <WorkflowBuilder />,
      },
      {
        path: "workflow/:workflowId",
        element: <WorkflowBuilder />,
      },
      {
        path: "knowledge-base",
        element: <KnowledgeBase />,
      },
      {
        path: "knowledge-base/create",
        element: <CreateKnowledgeBase />,
      },
      {
        path: "campaigns",
        element: <Campaigns />,
      },
      {
        path: "campaigns/create",
        element: <CreateCampaign />,
      },
      {
        path: "campaigns/:campaignId",
        element: <CampaignDetails />,
      },
      {
        path: "campaigns/:campaignId/customers",
        element: <CampaignCustomers />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "knowledge-base/:knowledgeBaseId/edit",
        element: <EditKnowledgeBase />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;

import { api } from "../apiService";

interface SignUpData {
  name: string;
  email: string;
}

export const signUpForBeta = async (data: SignUpData) => {
  const response = await api.post("/email-list/subscribe", data);
  return response.data;
};

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Divider,
  Text,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useReactFlow } from "@xyflow/react";

import { DrawerNodeFormProps, NodeData } from "./type";
import { useImprovedPrompt } from "./hooks/useImprovedPrompt";
import { useAvailableTools } from "./hooks/useAvailableTools";
import PromptSection from "./sections/PromptSection";
import KnowledgeBaseSection from "./sections/KnowledgeBaseSection";
import WebSearchSection from "./sections/WebSearchSection";
import ToolSection from "./sections/ToolSection";
import LoopConditionSection from "./sections/LoopConditionSection";
import GlobalNodeSection from "./sections/GlobalNodeSection";
import AdvancedOptionsSection from "./sections/AdvancedOptionsSection";
import VariablesSection from "./sections/VariablesSection";

const DrawerNodeForm: React.FC<DrawerNodeFormProps> = ({
  id,
  isOpen,
  onClose,
  data,
  nodeType,
}) => {
  const toast = useToast();
  const { updateNodeData } = useReactFlow();
  const [nodeData, setNodeData] = useState<NodeData>({ ...data });
  const {
    improvedPrompt,
    isImprovedPromptVisible,
    handleImprove,
    handleApply,
    handleIgnore,
  } = useImprovedPrompt(nodeData, setNodeData);

  const { availableTools } = useAvailableTools(nodeType);

  useEffect(() => {
    if (nodeType === "SMSNode") {
      setNodeData((prevState) => ({
        ...prevState,
        safeTools: ["text_message_prescription_validator"],
      }));
    }
  }, [nodeType, setNodeData]);

  const handleNodeUpdate = useCallback(() => {
    if (nodeType === "ToolNode" && nodeData.safeTools!.length === 0) {
      toast({
        title: "Tool not selected.",
        description: "Please select a tool to save the node.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    updateNodeData(id, { ...nodeData });
    onClose();
  }, [id, updateNodeData, onClose, nodeData, nodeType, toast]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text as="h1">Customise Node</Text>
        </DrawerHeader>

        <Divider mb={8} />

        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <Input
              placeholder="Node name"
              value={nodeData.label}
              onChange={(e) =>
                setNodeData({ ...nodeData, label: e.target.value })
              }
            />

            <PromptSection
              nodeData={nodeData}
              setNodeData={setNodeData}
              improvedPrompt={improvedPrompt}
              isImprovedPromptVisible={isImprovedPromptVisible}
              handleImprove={handleImprove}
              handleApply={handleApply}
              handleIgnore={handleIgnore}
            />

            {nodeType === "KnowledgeBaseNode" && (
              <KnowledgeBaseSection
                nodeData={nodeData}
                setNodeData={setNodeData}
              />
            )}

            {data.isPrimaryNode && (
              <WebSearchSection nodeData={nodeData} setNodeData={setNodeData} />
            )}

            {nodeType === "ToolNode" && (
              <ToolSection
                nodeData={nodeData}
                setNodeData={setNodeData}
                availableTools={availableTools}
              />
            )}

            {nodeType !== "EndCallNode" && (
              <LoopConditionSection
                nodeData={nodeData}
                setNodeData={setNodeData}
              />
            )}

            <GlobalNodeSection nodeData={nodeData} setNodeData={setNodeData} />

            {nodeType !== "EndCallNode" && (
              <AdvancedOptionsSection
                nodeData={nodeData}
                setNodeData={setNodeData}
              />
            )}

            {nodeType !== "EndCallNode" && (
              <VariablesSection nodeData={nodeData} setNodeData={setNodeData} />
            )}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleNodeUpdate}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerNodeForm;

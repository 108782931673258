import { api } from "../apiService";
import axios from "axios";

export interface KnowledgeBaseItem {
  organizationId: string;
  knowledgeBaseId: string;
  name: string;
  description: string;
  updatedAt: string | null;
  uploadStatus: string;
  knowledgeBaseType: string;
}

interface PresignedUrlResponse {
  uploadUrl: string;
  key: string;
  s3Uri: string;
}

export const getKnowledgeBaseMetadata = async (
  organizationId: string,
  knowledgeBaseId: string
) => {
  const response = await api.get(
    `/knowledge-base/get-knowledge-base/${organizationId}/${knowledgeBaseId}`
  );
  return response.data;
};

export const getKnowledgeBaseMetadataList = async (organizationId: string) => {
  const response = await api.get(
    `/knowledge-base/get-knowledge-base-metadata/${organizationId}`
  );
  return response.data;
};

export const createKnowledgeBase = async (payload: {
  organizationId: string;
  title: string;
  description: string;
  knowledgeBaseType: string;
  s3Uris: string[];
}) => {
  const response = await api.post(
    "/knowledge-base/create-knowledge-base",
    payload
  );
  return response.data;
};

export const updateKnowledgeBase = async (formData: FormData) => {
  const response = await api.post(
    "/knowledge-base/update-knowledge-base",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deleteKnowledgeBase = async (
  organizationId: string,
  knowledgeBaseId: string
) => {
  const response = await api.delete(`/knowledge-base/delete-knowledge-base`, {
    data: {
      organizationId,
      knowledgeBaseId,
    },
  });
  return response.data;
};

export const getPresignedUrls = async (
  organizationId: string,
  files: { name: string; type: string }[]
) => {
  const response = await api.post("/knowledge-base/get-presigned-urls", {
    organizationId,
    files: files.map((f) => ({ fileName: f.name, contentType: f.type })),
  });
  return response.data as PresignedUrlResponse[];
};

export const uploadFileToS3 = async (url: string, file: File) => {
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
    transformRequest: [(data) => data],
  });
  return response.data;
};

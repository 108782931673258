import React, { useCallback, useState, useEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  HStack,
  Input,
  useToast,
  VStack,
  Flex,
} from "@chakra-ui/react";
import {
  ReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  BackgroundVariant,
  Panel,
  Controls,
  Node,
  Edge,
  OnConnect,
  OnNodesChange,
  OnEdgesChange,
  NodeTypes,
  EdgeTypes,
} from "@xyflow/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import "@xyflow/react/dist/style.css";

import ProcessNode from "../../components/Nodes/ProcessNode";
import ProcessEdge from "../../components/Edges/ProcessEdge";
import {
  createOrganisationWorkflow,
  getOrganisationWorkflow,
  updateOrganisationWorkflow,
  WorkflowConfig,
} from "../../api/workflows";

const AgentBuilder: React.FC = () => {
  const { workflowId } = useParams<{ workflowId: string | undefined }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [workflowName, setWorkflowName] = useState("");

  const nodeTypes: NodeTypes = useMemo(() => ({ ProcessNode }), []);
  const edgeTypes: EdgeTypes = useMemo(() => ({ ProcessEdge }), []);

  useEffect(() => {
    if (workflowId) {
      getWorkflowState();
    }
  }, [workflowId]);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getWorkflowState = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: WorkflowConfig = await getOrganisationWorkflow(
        "1",
        workflowId!
      );
      setNodes(response.configuration.nodes);
      setEdges(response.configuration.edges);
      setWorkflowName(response.workflowName);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [workflowId]);

  const saveWorkflowState = useCallback(async () => {
    if (nodes.length === 0) {
      return;
    }

    const data = {
      configuration: { nodes, edges },
      updatedBy: "test@test.com",
      workflowName: workflowName,
    };

    try {
      setIsLoading(true);
      if (workflowId) {
        await updateOrganisationWorkflow("1", workflowId, data);
      } else {
        const workflow: WorkflowConfig = await createOrganisationWorkflow(
          "1",
          data
        );
        navigate(`/dashboard/workflow/${workflow.workflowId}`, {
          replace: true,
        });
      }
      toast({
        title: "Success",
        description: "Agent state saved!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [workflowId, workflowName, nodes, edges]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect: OnConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "ProcessEdge",
            data: { edgeCondition: "", edgeDescription: "" },
          },
          eds
        )
      ),
    []
  );

  const handleAddNode = useCallback(() => {
    const nodeId = nanoid();

    const newNode: Node = {
      id: nodeId,
      data: {
        label: `New Node`,
        prompt: "",
        tools: [],
        isPrimaryNode: false,
        sensitiveTools: [],
        allowWebSearch: false,
      },
      type: "ProcessNode",
      position: {
        x: Math.random() * 500,
        y: Math.random() * 300,
      },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [nodes]);

  return (
    <Flex height="100%" flexDirection="column">
      <Box flex={1} position="relative">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{ hideAttribution: true }}
        >
          <Background color="#ccc" variant={BackgroundVariant.Cross} />
          <Panel position="top-left">
            <Button
              ml={2}
              colorScheme="blue"
              onClick={handleAddNode}
              isDisabled={isLoading}
              size={"sm"}
            >
              Add Node
            </Button>
          </Panel>
          <Panel position="top-right">
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <HStack>
                <VStack>
                  <Input
                    bgColor="white"
                    variant="outline"
                    placeholder="Give your workflow a name"
                    value={workflowName}
                    onChange={(event) => setWorkflowName(event.target.value)}
                    isDisabled={isLoading}
                    size={"sm"}
                  />
                </VStack>
                <VStack>
                  <Button
                    colorScheme="blue"
                    onClick={saveWorkflowState}
                    isLoading={isLoading}
                    size={"sm"}
                  >
                    Save
                  </Button>
                </VStack>
              </HStack>
            </Box>
          </Panel>
          <Controls />
        </ReactFlow>
      </Box>
    </Flex>
  );
};

export default AgentBuilder;

import React from "react";
import { Alert, AlertIcon, Box, List, ListItem } from "@chakra-ui/react";

interface FormErrorsProps {
  errors: Record<string, string>;
}

const FormErrors: React.FC<FormErrorsProps> = ({ errors }) => {
  const errorMessages = Object.values(errors);

  if (errorMessages.length === 0) return null;

  return (
    <Alert status="error" mb={4}>
      <AlertIcon />
      <Box>
        <List spacing={1}>
          {errorMessages.map((error, index) => (
            <ListItem key={index}>{error}</ListItem>
          ))}
        </List>
      </Box>
    </Alert>
  );
};

export default FormErrors;

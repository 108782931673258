import React, { useCallback } from "react";
import {
  Box,
  Text,
  Divider,
  HStack,
  Icon,
  IconButton,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { Handle, Position, useReactFlow } from "@xyflow/react";

import { CiPlay1 } from "react-icons/ci";
import { FaRegClone } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

import DrawerNodeForm from "./DrawerNodeForm";
import { NodeViewProps } from "./type";
import { availableNodeTypes } from "../../pages/CPWBuilder/nodeTypes";
import { nanoid } from "nanoid";

const getNodeIcon = (nodeType: string) => {
  const node = availableNodeTypes.filter((node) => node.type === nodeType)[0];
  return node.icon;
};

const NodeView: React.FC<NodeViewProps> = ({
  id,
  data,
  isOpen,
  onClose,
  onOpen,
  nodeType = "normal",
  selected = false,
}: NodeViewProps) => {
  const { deleteElements, addNodes, getNode } = useReactFlow();

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  const duplicateNode = useCallback(() => {
    const node: any = getNode(id);
    const position = {
      x: node.position.x + 350,
      y: node.position.y + 0,
    };

    const newNodeId = nanoid();
    const newNodeName = `${node.data.label} (Clone-${newNodeId.slice(-3)})`;

    addNodes({
      ...node,
      selected: false,
      dragging: false,
      id: newNodeId,
      data: { ...node.data, label: newNodeName },
      position,
    });
  }, [id, getNode, addNodes]);

  const icon = data.isPrimaryNode ? CiPlay1 : getNodeIcon(data.nodeInfo.type);
  const nodeText = data.isPrimaryNode ? "Start" : data.label;
  const nodeTypeName = data.nodeInfo.name;
  return (
    <>
      <HStack alignItems={"flex-start"}>
        <Box
          border={selected ? "2px dotted blue" : "1px solid gray"}
          bgColor={"white"}
          borderRadius={5}
          width={300}
          maxH={200}
          overflow={"clip"}
          onDoubleClick={onOpen} // Added onDoubleClick event
          flex={1}
        >
          <HStack
            spacing={2}
            justifyContent="space-between"
            px={2}
            fontWeight={"500"}
          >
            <HStack spacing={0}>
              <Icon as={icon} />
              <Text p={2}>{nodeText}</Text>
            </HStack>
            <Text color={"gray"}>{nodeTypeName}</Text>
          </HStack>

          <Divider />

          <Box p={2}>
            <Text>{data.prompt}</Text>
          </Box>

          <>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
          </>
        </Box>
        {selected && (
          <Box bgColor={"white"} height={"100%"} p={2}>
            <VStack>
              <Tooltip label="Delete Node">
                <IconButton
                  variant="outline"
                  colorScheme="red"
                  aria-label="Delete Node"
                  size={"xs"}
                  onClick={onDelete}
                  icon={<MdDeleteOutline />}
                />
              </Tooltip>
              <Tooltip label="Clone Node">
                <IconButton
                  variant="outline"
                  colorScheme="blue"
                  aria-label="Delete Node"
                  size={"xs"}
                  onClick={duplicateNode}
                  icon={<FaRegClone />}
                />
              </Tooltip>
            </VStack>
          </Box>
        )}
      </HStack>

      <DrawerNodeForm
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        nodeType={nodeType}
      />
    </>
  );
};

export default NodeView;

import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  FormErrorMessage,
  Text,
  useToast,
  Checkbox,
  Spinner,
  Center,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { uploadCustomerFile, getCustomers } from "../../../api/customers";
import Papa from "papaparse";
import { validateCSVData, CSVRow } from "../../../utils/csvValidation";

interface CustomerDataProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

interface Organization {
  id: number;
  name: string;
  createDate: string;
  updateDate: string;
}

interface Customer {
  created_at: string;
  updated_at: string;
  customer_id: number;
  customer_name: string;
  phone_number: string;
  email: string;
  additional_info: Record<string, any>;
  source: string;
  organization: Organization;
}

const CustomerData: React.FC<CustomerDataProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);

  useEffect(() => {
    if (formData.dataSource === "existing") {
      fetchCustomers();
    }
  }, [formData.dataSource]);

  const fetchCustomers = async () => {
    setIsLoadingCustomers(true);
    try {
      const fetchedCustomers = await getCustomers();
      setCustomers(fetchedCustomers);
    } catch (error) {
      console.error("Error fetching customers:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to load customers",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Parse and validate CSV
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const validation = validateCSVData(
            results as Papa.ParseResult<CSVRow>
          );

          if (!validation.isValid) {
            setSelectedFile(null);
            setFormData({
              ...formData,
              customerFile: null,
            });
            toast({
              title: "Invalid CSV File",
              description: validation.error,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
          } else {
            setSelectedFile(file);
            setIsFileUploaded(false);
            setFormData({
              ...formData,
              customerFile: file,
            });
            toast({
              title: "File Validated Successfully",
              description: `CSV file contains ${results.data.length} valid records.`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        error: () => {
          setSelectedFile(null);
          setFormData({
            ...formData,
            customerFile: null,
          });
          toast({
            title: "Error Reading CSV File",
            description: "Please ensure your file is a valid CSV file.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      });
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      const response = await uploadCustomerFile(file);
      setIsFileUploaded(true);
      setFormData({
        ...formData,
        uploadedFileUrl: response.fileUrl,
      });
      toast({
        title: "File uploaded successfully",
        description: "Your customer data has been processed",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return response;
    } catch (error) {
      setIsFileUploaded(false);
      console.error("Error uploading file:", error);
      toast({
        title: "Upload failed",
        description:
          error instanceof Error
            ? error.message
            : "An error occurred while uploading the file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleUploadConfirm = async () => {
    if (!selectedFile) {
      toast({
        title: "No file selected",
        description: "Please select a file first",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsUploading(true);
    try {
      await handleFileUpload(selectedFile);
      // Don't clear the selected file here as we want to keep it in the form data
    } catch (error) {
      // Error is handled in handleFileUpload
    } finally {
      setIsUploading(false);
    }
  };

  const handleCustomerSelect = (customer: Customer) => {
    const currentSelected = formData.selectedCustomers || [];
    const newSelected = currentSelected.find(
      (c: any) => c.customer_id === customer.customer_id
    )
      ? currentSelected.filter(
          (c: any) => c.customer_id !== customer.customer_id
        )
      : [...currentSelected, customer];

    setFormData({
      ...formData,
      selectedCustomers: newSelected,
    });
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      selectedCustomers: e.target.checked ? customers : [],
    });
  };

  return (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.dataSource}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Data Source</FormLabel>
          <Tooltip
            label="Choose how to add customers to your campaign - upload a file or select existing customers"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <RadioGroup
          value={formData.dataSource}
          onChange={(value) => setFormData({ ...formData, dataSource: value })}
        >
          <HStack spacing={4}>
            <Radio value="file">Upload File</Radio>
            <Radio value="existing">Select Existing Customers</Radio>
          </HStack>
        </RadioGroup>
        <FormErrorMessage>{errors.dataSource}</FormErrorMessage>
      </FormControl>

      {formData.dataSource === "file" ? (
        <FormControl isRequired isInvalid={!!errors.customerFile}>
          <HStack spacing={1} mb={1}>
            <FormLabel mb={0}>Upload Customer Data File</FormLabel>
            <Tooltip
              label="Upload a CSV file containing customer information. Must include phone_number column"
              placement="top-start"
            >
              <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
            </Tooltip>
          </HStack>
          <Text fontSize="sm" color="gray.600" mb={2}>
            Upload a CSV file. The first row should be the headers of the table,
            and your headers should not include any special characters other
            than hyphens (-) or underscores (_). The column containing phone
            numbers must have a header labeled as "phone_number".
          </Text>
          <VStack spacing={4} align="flex-start">
            <HStack spacing={4}>
              <Button as="label" htmlFor="csv-upload">
                {isFileUploaded ? "Change File" : "Select CSV"}
                <input
                  id="csv-upload"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
              </Button>
              {selectedFile && (
                <HStack spacing={2}>
                  <Text fontSize="sm" color="gray.600">
                    {selectedFile.name}
                  </Text>
                  {isFileUploaded && (
                    <Text fontSize="sm" color="green.500">
                      (Uploaded)
                    </Text>
                  )}
                </HStack>
              )}
            </HStack>
            {selectedFile && !isFileUploaded && (
              <Button
                colorScheme="blue"
                onClick={handleUploadConfirm}
                isLoading={isUploading}
                loadingText="Uploading..."
              >
                Upload File
              </Button>
            )}
          </VStack>
          <FormErrorMessage>{errors.customerFile}</FormErrorMessage>
        </FormControl>
      ) : (
        <FormControl isRequired isInvalid={!!errors.selectedCustomers}>
          <HStack spacing={1} mb={1}>
            <FormLabel mb={0}>Select Customers</FormLabel>
            <Tooltip
              label="Choose existing customers to include in your campaign"
              placement="top-start"
            >
              <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
            </Tooltip>
          </HStack>
          <Box overflowX="auto">
            {isLoadingCustomers ? (
              <Center p={8}>
                <Spinner />
              </Center>
            ) : (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        isChecked={
                          formData.selectedCustomers?.length ===
                          customers.length
                        }
                        isIndeterminate={
                          formData.selectedCustomers?.length > 0 &&
                          formData.selectedCustomers?.length < customers.length
                        }
                        onChange={handleSelectAll}
                      />
                    </Th>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th>Email</Th>
                    <Th>Source</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {customers.map((customer) => (
                    <Tr key={customer.customer_id}>
                      <Td>
                        <Checkbox
                          isChecked={formData.selectedCustomers?.some(
                            (c: any) => c.customer_id === customer.customer_id
                          )}
                          onChange={() => handleCustomerSelect(customer)}
                        />
                      </Td>
                      <Td>{customer.customer_name}</Td>
                      <Td>{customer.phone_number}</Td>
                      <Td>{customer.email || "-"}</Td>
                      <Td>
                        <Text noOfLines={1} maxW="200px">
                          {customer.source}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>
          {formData.selectedCustomers?.length > 0 && (
            <Text mt={2} fontSize="sm" color="gray.600">
              {formData.selectedCustomers.length} customer
              {formData.selectedCustomers.length === 1 ? "" : "s"} selected
            </Text>
          )}
          <FormErrorMessage>{errors.selectedCustomers}</FormErrorMessage>
        </FormControl>
      )}
    </VStack>
  );
};

export default CustomerData;

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  useToast,
  HStack,
  IconButton,
  VStack,
  Spinner,
  Divider,
  Button,
  Tooltip,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import axios from "axios";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { FaRegClone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  createOrganisationAgent,
  deleteOrganisationAgent,
  getOrganisationAgent,
  getOrganisationAgents,
} from "../../api/agents";
import { AgentConfig } from "./type";
import { nanoid } from "nanoid";

const Agents: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState<AgentConfig[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredAgents = agents.filter((agent) =>
    agent.agentName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    getAgents();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getOrganisationAgents("1");
      setAgents(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deletePathway = useCallback(async (agentId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteOrganisationAgent("1", agentId);
        setAgents((prevAgents) =>
          prevAgents.filter((agent) => agent.agentId !== agentId)
        );
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreatePathway = useCallback(
    (type: string) => {
      if (type === "cpw") {
        navigate("/dashboard/agent/cpw/new");
      } else {
        navigate("/dashboard/agent/mna/new");
      }
    },
    [navigate]
  );

  const handleEditPathway = useCallback(
    (agentId: string) => {
      const agent = agents.filter((agent) => agent.agentId === agentId)[0];
      if (agent.type === "cpw") {
        navigate(`/dashboard/agent/cpw/${agentId}`);
      } else {
        navigate(`/dashboard/agent/mna/${agentId}`);
      }
    },
    [navigate, agents]
  );

  const clonePathway = useCallback(
    async (agentId: string) => {
      setIsLoading(true);
      try {
        const agentToClone = agents.find((agent) => agent.agentId === agentId);
        if (agentToClone == null) {
          return;
        }

        // get the nodes and edges here
        const currentPathwayConfiguration = await getOrganisationAgent(
          "1",
          agentToClone.agentId
        );
        // send a call to create
        const randomId = nanoid();
        const agent: AgentConfig = await createOrganisationAgent("1", {
          ...currentPathwayConfiguration,
          callNumber: "NA",
          agentName: `${agentToClone.agentName} (Clone-${randomId.slice(-3)})`,
        });
        // redirect to the cloned pathway
        navigate(`/dashboard/agent/cpw/${agent.agentId}`);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [agents]
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <HStack mb={5}>
          <Text as="h1" fontSize="2xl">
            Agents
          </Text>
        </HStack>

        <HStack mb={4}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="teal"
            aria-label="Create Agent"
            onClick={() => handleCreatePathway("mna")}
          >
            + Traditional Agent
          </Button>
          <Button
            size="sm"
            variant="outline"
            colorScheme="teal"
            aria-label="Create Agent"
            onClick={() => handleCreatePathway("cpw")}
          >
            + Communication Pathway Agent
          </Button>
        </HStack>

        <InputGroup mb={4}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search Pathways..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>

        {isLoading ? (
          <Spinner mt={5} />
        ) : (
          <Box>
            <HStack spacing={4} wrap="wrap">
              {filteredAgents.map((agent) => (
                <Box
                  key={agent.agentId}
                  bgColor="gray.100"
                  borderRadius={5}
                  p={2}
                  my={2}
                  height={180}
                  width={{ base: "100%", sm: "48%", md: "30%" }} // Adjust width for grid
                >
                  <VStack
                    alignItems={"start"}
                    justifyContent={"space-between"}
                    height={"100%"}
                  >
                    <VStack
                      alignItems={"start"}
                      width={"90%"}
                      overflow={"hidden"}
                    >
                      <Text fontSize={"lg"}>
                        {agent.agentName || agent.agentId}
                      </Text>
                      <Text fontSize={"sm"}>
                        {agent.type === "cpw"
                          ? "Communication PathWay Agent"
                          : "Traditional Agent"}
                      </Text>
                      {agent.callNumber.replace("NA", "").trim() !== "" ? (
                        <Text fontSize={"sm"} color={"green.400"}>
                          Connected to - {agent.callNumber}
                        </Text>
                      ) : (
                        ""
                      )}
                    </VStack>

                    <Box width={"100%"}>
                      <Divider borderColor={"black"} />
                      <HStack justifyContent="space-between" mt={2}>
                        <HStack>
                          <Text>{agent.updatedBy}</Text>
                        </HStack>
                        <HStack>
                          <Tooltip label="Edit Pathway">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Edit Pathway"
                              icon={<EditIcon />}
                              onClick={() => handleEditPathway(agent.agentId)}
                            />
                          </Tooltip>
                          <Tooltip label="Clone Pathway">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="teal"
                              aria-label="Clone Pathway"
                              icon={<FaRegClone />}
                              onClick={() => clonePathway(agent.agentId)} // Updated to call cloneAgent
                            />
                          </Tooltip>
                          <Tooltip label="Delete Pathway">
                            <IconButton
                              size="sm"
                              variant="outline"
                              colorScheme="red"
                              aria-label="Delete Pathway"
                              icon={<DeleteIcon />}
                              onClick={() => deletePathway(agent.agentId)}
                            />
                          </Tooltip>
                        </HStack>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </HStack>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Agents;

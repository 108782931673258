import Papa from "papaparse";

export interface CSVRow {
  customer_name?: string;
  phone_number?: string;
  email?: string;
  additional_info?: string;
  [key: string]: string | undefined;
}

export const validateCSVData = (
  results: Papa.ParseResult<CSVRow>
): { isValid: boolean; error?: string } => {
  const headers = results.meta.fields || [];

  // Check required headers
  if (!headers.includes("customer_name")) {
    return {
      isValid: false,
      error:
        "Missing required column 'customer_name'. Please ensure your CSV file has a 'customer_name' column.",
    };
  }

  if (!headers.includes("phone_number")) {
    return {
      isValid: false,
      error:
        "Missing required column 'phone_number'. Please ensure your CSV file has a 'phone_number' column.",
    };
  }

  // Check for invalid headers
  const validHeaders = [
    "customer_name",
    "phone_number",
    "email",
    "additional_info",
  ];
  const invalidHeaders = headers.filter(
    (header) => !validHeaders.includes(header)
  );
  if (invalidHeaders.length > 0) {
    return {
      isValid: false,
      error: `Invalid column(s) found: ${invalidHeaders.join(
        ", "
      )}. Only allowed columns are: ${validHeaders.join(", ")}.`,
    };
  }

  // Validate data in each row
  for (let i = 0; i < results.data.length; i++) {
    const row = results.data[i];
    const rowNum = i + 2; // Add 2 because 1 is header row and we want 1-based indexing

    // Skip empty rows
    if (Object.values(row).every((value) => !value)) continue;

    // Check required fields
    if (!row.customer_name?.trim()) {
      return {
        isValid: false,
        error: `Row ${rowNum}: Missing customer name. Customer name is required.`,
      };
    }

    if (!row.phone_number?.trim()) {
      return {
        isValid: false,
        error: `Row ${rowNum}: Missing phone number. Phone number is required.`,
      };
    }

    // Validate phone number format (basic validation)
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (!phoneRegex.test(row.phone_number.trim())) {
      return {
        isValid: false,
        error: `Row ${rowNum}: Invalid phone number format. Phone numbers should be in E.164 format (e.g., +1234567890).`,
      };
    }

    // Validate email format if provided
    if (row.email && row.email.trim()) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(row.email.trim())) {
        return {
          isValid: false,
          error: `Row ${rowNum}: Invalid email format.`,
        };
      }
    }

    // Validate additional_info JSON format if provided
    if (row.additional_info && row.additional_info.trim()) {
      console.log(row.additional_info, typeof row.additional_info);
      try {
        // First, try parsing as is
        try {
          JSON.parse(row.additional_info);
        } catch {
          // If direct parsing fails, try cleaning the string
          const cleanJson = row.additional_info
            .trim()
            // Remove surrounding quotes (single or double)
            .replace(/^['"]|['"]$/g, "")
            // Replace escaped quotes with regular quotes
            .replace(/\\"/g, '"')
            // Handle Excel-style double quotes
            .replace(/""/g, '"');

          JSON.parse(cleanJson);
        }
      } catch (e) {
        console.log("Failed to parse JSON:", row.additional_info); // For debugging
        return {
          isValid: false,
          error: `Row ${rowNum}: Invalid JSON format in additional_info column. The JSON should be properly formatted, for example: {"age":25,"gender":"male"}. Received: ${row.additional_info}`,
        };
      }
    }
  }

  return { isValid: true };
};

import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Button,
  IconButton,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  getKnowledgeBaseMetadataList,
  KnowledgeBaseItem,
  deleteKnowledgeBase,
} from "../../api/knowledgebase";

const KnowledgeBase: React.FC = () => {
  const navigate = useNavigate();
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBaseItem[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchKnowledgeBases();
  }, []);

  const fetchKnowledgeBases = async () => {
    try {
      const response = await getKnowledgeBaseMetadataList("1");
      const formattedKnowledgeBases = response.map(
        (kb: any, index: number) => ({
          knowledgeBaseId: kb.knowledgeBaseId,
          name: kb.name || "Untitled",
          description: kb.description || "No description available",
          updatedAt: kb.updatedAt || null,
          uploadStatus: kb.uploadStatus || "completed",
          knowledgeBaseType: kb.knowledgeBaseType || "",
        })
      );
      setKnowledgeBases(formattedKnowledgeBases);
    } catch (error) {
      console.error("Error fetching knowledge bases:", error);
    }
  };

  const handleCreateKnowledgeBase = () => {
    navigate("/dashboard/knowledge-base/create");
  };

  const handleEditKnowledgeBase = (knowledgeBaseId: string) => {
    navigate(`/dashboard/knowledge-base/${knowledgeBaseId}/edit`);
  };

  const handleDeleteKnowledgeBase = (knowledgeBaseId: string) => {
    if (
      window.confirm("Are you sure you want to delete this knowledge base?")
    ) {
      deleteKnowledgeBase("1", knowledgeBaseId)
        .then(() => {
          fetchKnowledgeBases(); // Refresh the list after deletion
        })
        .catch((error) => {
          console.error("Error deleting knowledge base:", error);
        });
    }
  };

  const filteredKnowledgeBases = knowledgeBases.filter((kb) =>
    kb.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box height={"100%"}>
      <HStack mb={5}>
        <Text as="h1" fontSize="2xl">
          Knowledge Base
        </Text>
      </HStack>
      <HStack mb={4}>
        <Button
          size="sm"
          variant="outline"
          colorScheme="teal"
          aria-label="Create Knowledge Base"
          onClick={handleCreateKnowledgeBase}
        >
          + New Knowledge Base
        </Button>
      </HStack>

      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.400" />
        </InputLeftElement>
        <Input
          placeholder="Search knowledge bases..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      <Box>
        <HStack spacing={4} wrap="wrap">
          {filteredKnowledgeBases.map((knowledgeBase) => (
            <Box
              key={knowledgeBase.knowledgeBaseId}
              bgColor={
                knowledgeBase.uploadStatus === "in_progress"
                  ? "gray.200"
                  : "gray.100"
              }
              borderRadius={5}
              p={2}
              my={2}
              height={180}
              width={{ base: "100%", sm: "48%", md: "30%" }}
              position="relative"
              opacity={knowledgeBase.uploadStatus === "in_progress" ? 0.7 : 1}
            >
              <HStack position="absolute" top={2} right={2} spacing={2}>
                {knowledgeBase.uploadStatus === "in_progress" && (
                  <Box
                    bg="orange.400"
                    color="white"
                    fontSize="xs"
                    px={2}
                    py={1}
                    borderRadius="md"
                  >
                    Processing...
                  </Box>
                )}
                <Box
                  bg="blue.400"
                  color="white"
                  fontSize="xs"
                  px={2}
                  py={1}
                  borderRadius="md"
                  textTransform="capitalize"
                >
                  {knowledgeBase.knowledgeBaseType}
                </Box>
              </HStack>
              <VStack
                alignItems={"start"}
                justifyContent={"space-between"}
                height={"100%"}
              >
                <VStack alignItems={"start"} width={"90%"} overflow={"hidden"}>
                  <Text fontSize={"lg"} fontWeight="bold">
                    {knowledgeBase.name}
                  </Text>
                  <Text fontSize={"sm"} noOfLines={2}>
                    {knowledgeBase.description}
                  </Text>
                </VStack>

                <Box width={"100%"}>
                  <Divider borderColor={"black"} />
                  <HStack justifyContent="space-between" mt={2}>
                    <HStack>
                      <Text fontSize="xs">
                        {knowledgeBase.updatedAt || "No date available"}
                      </Text>
                    </HStack>
                    <HStack>
                      <IconButton
                        size="sm"
                        variant="outline"
                        colorScheme="teal"
                        aria-label="Edit Article"
                        icon={<EditIcon />}
                        onClick={() =>
                          handleEditKnowledgeBase(knowledgeBase.knowledgeBaseId)
                        }
                        isDisabled={
                          knowledgeBase.uploadStatus === "in_progress"
                        }
                      />
                      <IconButton
                        size="sm"
                        variant="outline"
                        colorScheme="red"
                        aria-label="Delete Article"
                        icon={<DeleteIcon />}
                        onClick={() =>
                          handleDeleteKnowledgeBase(
                            knowledgeBase.knowledgeBaseId
                          )
                        }
                        isDisabled={
                          knowledgeBase.uploadStatus === "in_progress"
                        }
                      />
                    </HStack>
                  </HStack>
                </Box>
              </VStack>
            </Box>
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default KnowledgeBase;
